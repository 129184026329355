<template>
    <span :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t('swifter')}) : ''"
          :class="disabled ? 'module-disabled-struct' : ''">
        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">

         <Card :title="$t(title)" is-dark mode="default" id="smtp-config" class="uk-height-1-1">
             <Description v-if="description" :text="$t(description)"/>

             <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                 <TextField
                     v-model="inputField.value"
                     :autocomplete="inputField.autocomplete"
                     :autofocus="inputField.autofocus"
                     :field-key="key"
                     :hasPasswordMeter="inputField.hasPasswordMeter"
                     :icon="inputField.icon"
                     :is-optional="inputField.optional"
                     :label="inputField.label"
                     :name="inputField.name"
                     :options="inputField.options"
                     :rules="inputField.rules"
                     :steps="inputField.steps"
                     :type="inputField.type"

                     :on-enter="actionUpdate"
                     :validate="validationTrigger"
                     @setValid="setValid"
                 />

                 <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
             </span>

             <div class="uk-margin-medium-bottom"/>
             <Button
                 :action="actionUpdate"
                 :is-loading="loading"
                 :text="$t('x.save', {x: $t(title)})"
                 has-action
                 is-large
                 is-max
                 mode="primary"
             />

             <div v-if="error" class="uk-margin-medium-bottom"/>
             <ErrorMessage :error="error"/>

             <div v-if="success" class="uk-margin-medium-bottom"/>
             <SuccessMessage :success="success" :message="$t('x.changes.saved', {x: $t(title)})"/>
         </Card>

        </span>
    </span>

</template>


<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Description from "@/components/Description.vue";
import Button from "@/components/generic/Button.vue";
import TextField from "@/components/generic/TextField.vue";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";

export default {
    name: 'SwifterNameForm',
    components: { ErrorMessage, Card, TextField, Button, Description, SuccessMessage},
    props: {
        text: String,
        disabled: Boolean,
    },
    data() {
        return {
            title: "name",
            description: "swifter.text.message",

            loading: false,
            success: false,
            error: null,

            validationTrigger: 0,
            inputFields: [
                {
                    value: this.text,
                    valid: true,

                    name: "name",
                    label: "name",
                    icon: "hashtag",
                    rules: "required|max:320",
                },
            ],
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.success = false;
            this.inputFields[fieldKey].valid = valid
        },
        actionUpdate() {
            this.validationTrigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loading = true;

            let http = axios.create();
            http.put(this.apiUrl + '/swifter-name', {
                name: this.inputFields[0].value,

            }).then(() => {
                this.error = null;
                this.success = true;
                this.loading = false;

                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);

                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
