<template>
    <div
        :class="'site-struct ' + (pictures.length > 0 && getInfos && getInfos.terminal || isTerminalMode ? 'extended' : 'single')">

        <LoadingStruct is-dark :is-loading="!this.getInfos">


            <countdown v-if="requestTokenHeaders"
                       class="uk-hidden"
                       :end-time="new Date(requestTokenHeaders['expiry'] * 1000).getTime()"
                       @finish="handleTokenExpiry">
                <template
                    v-slot:process="countdown">
                    {{ countdown.timeObj.ceil.s }}
                </template>
            </countdown>

            <Screensaver v-if="isTerminalMode && !isTerminalWithAds"
                         @start="screensaverStart"
                         :terminal="getInfos.terminal"/>

            <div v-if="isTerminalMode">
                <Modal :title="$t('terminal.connector')" is-strict :id="modalIdTerminalConnectorChecker">

                    <TerminalConnnectorNotRunnning/>

                </Modal>
            </div>

            <Card v-if="!this.getInfos.articles" is-dark mode="default">

                <div v-if="isTerminalMode && !isTerminalConnectorRunning">
                    <div class="uk-flex uk-flex-center" uk-spinner/>
                </div>
                <div v-else>
                    {{ $t('article.none') }}
                </div>

            </Card>
            <Card v-else is-dark mode="default">

                <TerminalAdsHeader v-if="isTerminalWithAds"
                                   class="uk-margin-medium-bottom"
                                   :terminal="getInfos.terminal"/>

                <ul id="tab" class="uk-child-width-expand" uk-tab="animation: uk-animation-fade; swiping:false;">
                    <li>
                        <a v-on:click="refreshCroppeds()"
                           :class="isStartValid ? '' : 'uk-hidden'" :uk-tooltip="'title:'+$t('pictures')" href="#">1.
                            <Icon icon="image"/>
                            <span class="uk-visible@m"> {{ $t('pictures') }}</span></a>
                        <span :class="!isStartValid ? 'cursor-notallowed' : 'cursor-notallowed uk-hidden'"
                              :uk-tooltip="'title:'+$t('pictures')" href="#">1. <Icon
                            icon="image"/><span class="cursor-notallowed uk-visible@m"> {{
                                $t('pictures')
                            }}</span></span>
                    </li>
                    <li>
                        <a :class="isPicturesValid ? '' : 'uk-hidden'" :uk-tooltip="'title:'+$t('contact.details')"
                           v-on:click="validatePictures"
                           href="#">2.
                            <Icon icon="mail"/>
                            <span class="uk-visible@m"> {{ $t('contact.details') }}</span></a>
                        <span :class="!isPicturesValid ? 'cursor-notallowed' : 'cursor-notallowed uk-hidden'"
                              v-on:click="validatePictures"
                              :uk-tooltip="'title:'+$t('contact.details')"
                              href="#">2. <Icon
                            icon="mail"/><span class="cursor-notallowed uk-visible@m"> {{
                                $t('contact.details')
                            }}</span></span>
                    </li>
                    <li>
                        <a :class="isContactDetailsValid ? '' : 'uk-hidden'" :uk-tooltip="'title:'+$t('summary')"
                           v-on:click="validateContactDetails"
                           href="#">3.
                            <Icon icon="check"/>
                            <span class="uk-visible@m"> {{ $t('summary') }}</span></a>
                        <span :class="!isContactDetailsValid ? 'cursor-notallowed' : 'cursor-notallowed uk-hidden'"
                              v-on:click="validateContactDetails"
                              :uk-tooltip="'title:'+$t('summary')"
                              href="#">3. <Icon
                            icon="check"/><span class="cursor-notallowed uk-visible@m"> {{
                                $t('summary')
                            }}</span></span>
                    </li>
                </ul>

                <ul class="uk-switcher uk-margin">
                    <li>
                        <div v-if="pictures.length>0" class="total-struct uk-margin-medium-top">
                            <InfoBox class="uk-margin-medium-bottom">

                                <ul uk-accordion>
                                    <li>
                                        <a class="uk-accordion-title" href="#">
                                            <div class="uk-grid-collapse" uk-grid>
                                                <div class="uk-width-expand" uk-leader>{{
                                                        $t('total')
                                                    }}
                                                </div>
                                                <div class="uk-width-xsmall"/>
                                                <div>{{ getTotal }}</div>
                                            </div>
                                        </a>

                                        <div class="uk-accordion-content sticky-accordion-content">
                                            <div class="uk-grid-small" uk-grid>
                                                <div class="uk-width-expand" uk-leader>
                                                    {{ $t('service.fee') }}

                                                    <span v-if="getFeeDiscountBySource() != null"
                                                          class="graduated-prices">
                                                        ({{
                                                            $t('dropped.at')
                                                        }}  {{ getNumberWithCurrency(getFeeDiscountBySource()) }}:
                                                        <span v-if="isFeeDiscount">
                                                            <Icon :ratio="0.8" icon="check"/>)
                                                        </span>
                                                        <span v-else>
                                                            {{ $t('left') }} {{
                                                                getNumberWithCurrency(getFeeDiscountBySource() - getTotalSamplesPrice)
                                                            }})
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>{{ getNumberWithCurrency(getFee) }}</div>
                                            </div>

                                            <div class="uk-grid-small" uk-grid>
                                                <div class="uk-width-expand" uk-leader>
                                                    <span class="uk-badge badge">{{ getTotalSamplesCount }}</span>
                                                    {{ $t(getTotalSamplesCount === 1 ? 'picture' : 'pictures') }}
                                                </div>
                                                <div>{{ getNumberWithCurrency(getTotalSamplesPrice) }}</div>
                                            </div>

                                        </div>


                                        <progress :value="getUnifiedUploadPercentage" class="uk-progress"
                                                  max="100"/>
                                        <div class="upload-status uk-text-center upload-status-total">
                                            {{
                                                $t('upload.status.total', {x: getUnifiedUploadPercentage})
                                            }}
                                        </div>

                                    </li>
                                </ul>

                            </InfoBox>
                        </div>


                        <div v-if="pictures.length < getInfos.maxPicturesPerOrder" id="pictures-upload-1"
                             class="js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer uk-hidden"
                             v-on:click="triggerInputDialog()">
                            <div>
                                <Icon :ratio="2.2" class="upload-icon" icon="cloud-upload"/>
                            </div>

                            <div v-if="isDeviceMobile && isUploadInProgress">
                                <span class="uk-text-middle">{{ $t('upload.in.progress.hint') }}</span>
                            </div>
                            <div v-else>
                                <span class="uk-text-middle">{{ $t('x.drop.or.', {x: $t('pictures')}) }}</span>
                                <span class="uk-link uk-text-middle custom-link">{{ $t('select') }}</span>
                            </div>

                            <div class="uk-hidden" uk-form-custom>
                                <input id="pictures"
                                       ref="pictures"
                                       :accept="'.'+fileExtensions.join(', .')"
                                       multiple
                                       type="file"
                                       v-on:change="handleFilesUpload()"
                                       v-on:click="$refs.pictures.value=null"
                                />
                            </div>

                            <div v-if="picturesError" class="uk-margin-top"/>
                            <LabelWithError v-if="picturesError" :error="picturesError" :label="$t('pictures')"/>
                        </div>


                        <div v-if="pictures && pictures.length>0">
                            <Card is-dark mode="default">
                                <ul uk-accordion>
                                    <li>
                                        <a class="uk-accordion-title" href="#">
                                            {{ $t('entire.selection') }}
                                        </a>
                                        <div class="uk-accordion-content">
                                            <div class="uk-child-width-1-1 uk-grid-small" uk-grid>
                                                <div>
                                                    <LabelWithError v-if="false" :label="$t('format')+'*'"/>

                                                    <select v-model="unifiedFormat" class="uk-select"
                                                            v-on:change="unifiedId=getFirstArticleIDByFormat(unifiedFormat, unifiedPaper); successUnified= false">
                                                        <option v-for="format in getFormats"
                                                                :key="'f'+format"
                                                                :value="format">{{ format }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="uk-margin-small-top"/>
                                                <div>
                                                    <LabelWithError v-if="false" :label="$t('paper')+'*'"/>

                                                    <div
                                                        class="uk-height-1-1 uk-grid-small uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2 uk-grid-match"
                                                        uk-grid>
                                                        <div v-for="paper in getPapersByFormat(unifiedFormat)"
                                                             :key="paper.value">
                                                            <div
                                                                v-on:click="unifiedId= paper.value; unifiedPaper= paper.text; successUnified= false">
                                                                <LabelSelect
                                                                    :is-selected="paper.value === unifiedId">
                                                                    <div class="uk-text-center paper-text">
                                                                        {{ getPaperName(paper.text) }}
                                                                    </div>
                                                                    <div class="uk-text-center paper-price">
                                                                        {{ paper.price }}

                                                                        <div v-if="paper.minPrice">
                                                                            {{ paper.minPrice }}
                                                                            {{ $t('minimum.price') }}
                                                                        </div>
                                                                    </div>
                                                                </LabelSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="uk-margin-small-top"/>
                                                <div>
                                                    <Button :action="setUnifiedFormat" :text="$t('change')"
                                                            has-action
                                                            is-max is-large
                                                            mode="primary"/>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </Card>

                            <div class="uk-margin-medium-top uk-margin-medium-bottom"/>
                        </div>

                        <LabelWithError :error="picturesError" :label="$t('pictures')+'*'"/>
                        <div v-if="pictures && pictures.length>0" class="uk-margin-medium-bottom">

                            <div
                                :class="'uk-grid uk-grid-divider ' + (getInfos.terminal ? 'uk-child-width-1-3@xl uk-child-width-1-2@l uk-child-width-1-1' : 'uk-child-width-1-1')"
                                uk-grid>
                                <div v-for="item in sortedPictures" :key="'p'+item.originKey"
                                     :id="'picture-'+item.originKey">

                                    <hr :id="'picture-hr-'+item.originKey"
                                        v-if="false"
                                        class="uk-hr hr-dashed uk-margin-medium-bottom"/>

                                    <div :id="'picture-hr-'+item.originKey" uk-grid>
                                        <div
                                            :class="getInfos.terminal ? 'uk-width-large@xl uk-width-1-1' : 'uk-width-large@m uk-width-1-1'">
                                            <div class="uk-transition-toggle image-struct">
                                                <div class="uk-position-relative cropper-struct">

                                                    <span v-if="!item.thumbnailUrl">

                                                        <div class="uk-width-1-1 thumbnail uk-flex uk-flex-center"
                                                             uk-spinner/>

                                                    </span>
                                                    <span v-else>

                                                    <img v-if="!item.croppingEnabled"
                                                         class="cropper-disabled"
                                                         v-on:mouseover="addHighlight(item.originKey, false)"
                                                         v-on:mouseout="removeHighlight(item.originKey)"
                                                         v-on:click="addHighlight(item.originKey, true)"
                                                         :uk-tooltip="'title:'+$t('crop.disabled.message', {symbol: '<span uk-icon=\'icon: move\'></span>'})"
                                                         :src="item.thumbnailUrl"/>

                                                    <cropper
                                                        :src="item.thumbnailUrl"
                                                        :debounce="600"
                                                        :moveImage="true"
                                                        :auto-zoom="false"
                                                        :transitions="true"
                                                        :resizeImage="{ wheel: true, adjustStencil: true, touch: true}"
                                                        :ref="'cropper'+item.originKey"
                                                        @change="handleCropperChange(item.originKey)"
                                                        :default-size="{ width: 999999999, height: 999999999 }"
                                                        :class="'cropper uk-width-1-1' +(item.croppingEnabled ? '' : ' cropper-item-disabled')"
                                                        :stencil-props="{
                                                            aspectRatio: getCropperRatioByPictureKey(item.originKey),
                                                            class: 'cropper-stencil',
                                                            previewClass: 'cropper-stencil__preview',
                                                            draggingClass: 'cropper-stencil--dragging',
                                                            handlersClasses: {
                                                                default: 'cropper-handler',
                                                                eastNorth: 'cropper-handler--east-north',
                                                                westNorth: 'cropper-handler--west-north',
                                                                eastSouth: 'cropper-handler--east-south',
                                                                westSouth: 'cropper-handler--west-south',
                                                            },
                                                            handlersWrappersClasses: {
                                                                default: 'cropper-handler-wrapper',
                                                                eastNorth: 'cropper-handler-wrapper--east-north',
                                                                westNorth: 'cropper-handler-wrapper--west-north',
                                                                eastSouth: 'cropper-handler-wrapper--east-south',
                                                                westSouth: 'cropper-handler-wrapper--west-south',
                                                            },
                                                        }"
                                                    />

                                                        <div v-if="false"
                                                             class="uk-overlay uk-position-center">
                                                            <Icon icon="warning" :ratio="1.5"
                                                                  class="crop-icon-hovered"/>
                                                        </div>

                                                </span>
                                                </div>

                                                <div
                                                    :class="'uk-card uk-card-body uk-padding-xsmall control secondary-with-border'+(item.error ? ' image-error' : '')">
                                                    <div class="uk-flex-center uk-flex" uk-grid>


                                                        <div v-if="!isVariableHeight(item.originKey) || true">
                                                        <span v-if="item.croppingEnabled"
                                                              v-on:click="disableCropping(item.originKey)"
                                                              class="action-icon">
                                                            <Icon :ratio="1.3"
                                                                  :tooltip="$t('crop.disable')"
                                                                  class="cursor-pointer uk-form-warning"
                                                                  icon="move"/>
                                                        </span>

                                                            <span v-else
                                                                  v-on:click="enableCropping(item.originKey)"
                                                                  class="action-icon">
                                                            <Icon :ratio="1.3"
                                                                  :tooltip="$t('crop.enable')"
                                                                  :id="'crop-icon-'+item.originKey"
                                                                  class="cursor-pointer"
                                                                  icon="move"/>
                                                        </span>
                                                        </div>


                                                        <div v-if="!isVariableHeight(item.originKey)">
                                                        <span v-on:click="rotateCropping(item.originKey)"
                                                              class="action-icon">
                                                            <Icon :ratio="1.3"
                                                                  :tooltip="$t('rotate.selection')"
                                                                  class="cursor-pointer"
                                                                  icon="refresh"/>
                                                        </span>
                                                        </div>


                                                        <div>
                                                         <span v-if="item.cloneInProgress" class="action-icon">
                                                            <div :tooltip="$t('Duplicating')"
                                                                 class="cursor-notallowed" uk-spinner/>
                                                        </span>
                                                            <span
                                                                v-else-if="pictures.length < getInfos.maxPicturesPerOrder"
                                                                v-on:click="clonePicture(item.originKey)"
                                                                class="action-icon">
                                                            <Icon :ratio="1.3" :tooltip="$t('duplicate')"
                                                                  class="cursor-pointer" icon="copy"/>
                                                        </span>
                                                            <span v-else class="action-icon">
                                                               <Icon :ratio="1.3"
                                                                     :tooltip="$t('pictures.max.reached', {x: getInfos.maxPicturesPerOrder})"
                                                                     class="cursor-pointer" icon="copy"/>
                                                        </span>
                                                        </div>
                                                        <div>
                                                            <div class="uk-hidden" uk-form-custom>
                                                                <input :id="'pictures-'+item.originKey"
                                                                       :ref="'pictures-'+item.originKey"
                                                                       :accept="'.'+fileExtensions.join(', .')"
                                                                       type="file"
                                                                       v-on:change="replacePicture(item.originKey)"
                                                                />
                                                            </div>

                                                            <span v-on:click="triggerReplaceDialog(item.originKey)"
                                                                  class="action-icon">
                                                            <Icon :ratio="1.3" :tooltip="$t('replace')"
                                                                  class="cursor-pointer" icon="upload"/>
                                                        </span>
                                                        </div>

                                                        <div>
                                                        <span v-on:click="deletePicture(item.originKey)"
                                                              class="action-icon">
                                                            <Icon :ratio="1.3" :tooltip="$t('delete')"
                                                                  class="cursor-pointer" icon="trash"/>
                                                        </span>
                                                        </div>

                                                        <div
                                                            v-if="false && (getPictureWidthLowDiff(item) > 0 || getPictureHeightLowDiff(item) > 0)">
                                                        <span class="action-icon crop-icon-hovered">
                                                            <Icon :ratio="1.3"
                                                                  :tooltip="$t('resolution.too.low.message')"
                                                                  icon="warning"/>
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="upload-border">

                                                    <div class="uk-text-center"
                                                         v-if="getPictureWidthLowDiff(item) > 0 || getPictureHeightLowDiff(item) > 0">
                                                        <span
                                                            class="action-icon crop-icon-hovered uk-margin-xsmall-right">
                                                            <Icon :ratio="1.3" icon="warning"/>
                                                        </span>
                                                        <span class="resolution-message">
                                                             {{ $t('resolution.too.low.message') }}
                                                        </span>
                                                    </div>


                                                    <progress :value="item.uploadPercentage" class="uk-progress"
                                                              max="100"/>
                                                    <div class="upload-status uk-text-center">
                                                        {{ $t('upload.status', {x: item.uploadPercentage}) }}
                                                    </div>

                                                    <div v-if="item.error" class="uk-margin-medium-bottom"/>
                                                    <ErrorMessage
                                                        :custom-errors="[{code: 422, message: $t('files.type.error', {
                                                            name: $t('pictures'),
                                                            types: getFormattedTypesString(fileExtensions)
                                                            })+'.',
                                                        }]"
                                                        :error="item.error"/>

                                                    <div v-if="item.error" class="uk-margin-top"/>
                                                    <div
                                                        v-if="item.error && item.error.response && item.error.response.status === 422"
                                                        class="uk-margin-medium-top"
                                                        v-on:click="deletePicture(item.originKey)">
                                                        <Button :text="$t('delete')" is-large is-max
                                                                mode="primary"/>
                                                    </div>
                                                    <div v-else-if="item.error" class="uk-margin-medium-top"
                                                         v-on:click="retryUpload(item)">
                                                        <Button :text="$t('retry')" is-large is-max mode="primary"/>
                                                    </div>
                                                </div>


                                                <div v-if="false"
                                                     class="uk-position-cover uk-overlay-primary uk-flex uk-flex-center uk-flex-middle uk-transition-fade">
                                                    <div class="uk-position-center uk-text-center">

                                                        <div uk-grid>
                                                            <div>
                                                                <div uk-form-custom>
                                                                    <input :id="'pictures-'+item.originKey"
                                                                           :ref="'pictures-'+item.originKey"
                                                                           :accept="'.'+fileExtensions.join(', .')"
                                                                           type="file"
                                                                           v-on:change="replacePicture(item.originKey)"
                                                                           v-on:click="$refs.pictures.value=null"
                                                                    />
                                                                    <span class="uk-link">
                                                    <Icon :ratio="1.5" :uk-tooltip="'title:'+$t('replace')"
                                                          class="cursor-pointer" icon="upload"/>
                                                </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                    <span v-on:click="deletePicture(item.originKey)">
                                                <Icon :ratio="1.5" :uk-tooltip="'title:'+$t('delete')"
                                                      class="cursor-pointer" icon="trash"/>
                                            </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="uk-width-expand">

                                            <div class="uk-grid-small uk-child-width-1-1" uk-grid>

                                                <div>
                                                    <LabelWithError v-if="false" :label="$t('format')+'*'"/>

                                                    <select v-model="item.format"
                                                            class="uk-select"
                                                            v-on:change="item.articleID= getFirstArticleIDByFormat(item.format, item.paper); resetCroppingByKey(item.originKey); getAndSetMeasurement(item.originKey)">
                                                        <option v-for="format in getFormats"
                                                                :key="'f'+format"
                                                                :value="format">{{ format }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div
                                                    v-if="false">
                                                    <div class="uk-margin-small-top"/>
                                                    <InfoBox mode="warning">

                                                        <span v-if="getPictureWidthLowDiff(item) > 0">
                                                              {{
                                                                $t('picture.width.too.low', {diff: getPictureWidthLowDiff(item)})
                                                            }}
                                                        </span>

                                                        <span v-if="getPictureHeightLowDiff(item) > 0">
                                                              {{
                                                                $t('picture.length.too.low', {diff: getPictureHeightLowDiff(item)})
                                                            }}
                                                        </span>


                                                        <br/><br/>
                                                        {{ $t('picture.too.low.message') }}

                                                    </InfoBox>
                                                    <div class="uk-margin-top"/>
                                                </div>

                                                <div class="uk-margin-small-top"/>
                                                <div>
                                                    <LabelWithError v-if="false" :label="$t('paper')+'*'"/>

                                                    <div
                                                        class="uk-grid-small uk-child-width-1-2 uk-height-1-1 uk-grid-match"
                                                        uk-grid>
                                                        <div v-for="paper in getPapersByFormat(item.format)"
                                                             :key="paper.value">
                                                            <div
                                                                v-on:click="item.articleID= paper.value; item.paper= paper.text">
                                                                <LabelSelect
                                                                    :is-selected="paper.value === item.articleID">
                                                                    <div class="uk-text-center paper-text">
                                                                        {{ getPaperName(paper.text) }}
                                                                    </div>
                                                                    <div class="uk-text-center paper-price">
                                                                        {{ paper.price }}

                                                                        <div v-if="paper.minPrice">
                                                                            {{ paper.minPrice }} {{
                                                                                $t('minimum.price')
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </LabelSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="uk-margin-small-top"/>
                                                <div>
                                                    <LabelWithError v-if="false" :label="$t('amount')+'*'"/>

                                                    <div class="uk-grid-collapse" uk-grid>
                                                        <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount(item.originKey, -1)">
                                                            <Button mode="default" with-class="minus-button">
                                                                <Icon icon="minus"/>
                                                            </Button>
                                                        </span>
                                                        </div>
                                                        <div class="uk-width-expand uk-text-center item-amount">
                                                            <input v-model.number="item.amount"
                                                                   v-on:input="validateAmount(item.originKey)"
                                                                   :placeholder="$t('amount')"
                                                                   min="1"
                                                                   class="uk-input uk-text-center" type="number">
                                                        </div>
                                                        <div class="uk-width-auto">
                                                        <span v-on:click="manipulateAmount(item.originKey, +1)">
                                                            <Button mode="default" with-class="plus-button">
                                                                <Icon icon="plus"/>
                                                            </Button>
                                                        </span>
                                                        </div>
                                                    </div>


                                                    <span v-if="false"
                                                          v-on:click="manipulateAmount(item.originKey, +1)">
                                                    <Button is-round mode="default">+1</Button>
                                                </span>

                                                    <span v-if="false" class="uk-margin-small-left"
                                                          v-on:click="manipulateAmount(item.originKey, -1)">
                                                    <Button is-round mode="default">-1</Button>
                                                </span>

                                                    <Label v-if="false" is-large is-round>
                                                        <div class="uk-grid-collapse uk-flex uk-flex-middle"
                                                             uk-grid>
                                                            <div>
                                                        <span v-on:click="manipulateAmount(item.originKey, -1)">
                                                            <Icon class="cursor-pointer" icon="minus"/>
                                                        </span>
                                                            </div>
                                                            <div class="custom-input uk-flex uk-flex-center">
                                                                {{ item.amount }}
                                                            </div>
                                                            <div>
                                                        <span v-on:click="manipulateAmount(item.originKey, +1)">
                                                            <Icon class="cursor-pointer" icon="plus"/>
                                                        </span>
                                                            </div>
                                                        </div>
                                                    </Label>
                                                </div>

                                                <div
                                                    v-if="getArticleByID(item.articleID).graduatedPrices && getArticleByID(item.articleID).graduatedPrices.length>0"
                                                    class="uk-margin-top uk-margin-small-bottom">
                                                    <InfoBox class="graduated-prices">
                                                        <div
                                                            v-for="(graduatedPrice, key) in getArticleByID(item.articleID).graduatedPrices"
                                                            :key="key">

                                                        <span
                                                            v-if="graduatedPrice.amount-getGroupedPicturesAmountByArticleID(item.articleID)>0">
                                                            {{
                                                                $t('x.at.x.amount.left.x', {
                                                                    price: getNumberWithCurrency(graduatedPrice.price) + (getArticleByID(item.articleID).isHeightVariable ? ' ' + $t('per.x', {x: $t(getInfos.measurementUnit)}) : ''),
                                                                    amount: graduatedPrice.amount,
                                                                    required: graduatedPrice.amount - getGroupedPicturesAmountByArticleID(item.articleID),
                                                                })
                                                            }}
                                                        </span>
                                                            <span v-else>
                                                            {{
                                                                    $t('x.at.x.amount', {
                                                                        price: getNumberWithCurrency(graduatedPrice.price),
                                                                        amount: graduatedPrice.amount,
                                                                    })
                                                                }}

                                                            <Icon :ratio="0.8" icon="check"/>
                                                        </span>

                                                        </div>
                                                    </InfoBox>
                                                </div>

                                                <div class="uk-margin-small-top"/>

                                                <div v-if="isVariableHeight(item.originKey)">
                                                    <div class="uk-grid-small" uk-grid>
                                                        <div class="uk-width-expand" uk-leader>
                                                            {{ $t('pictureLength') }}
                                                            ({{
                                                                $t(getPictureFormatName(item.originKey) + '.format')
                                                            }})
                                                        </div>
                                                        <div>
                                                            <div v-if="item.measurementValue===0" uk-spinner/>
                                                            <span v-else>
                                                            {{
                                                                    formatMeasurement(item.measurementValue)
                                                                }} {{ $t(getInfos.measurementUnit) }}
                                                        </span>
                                                        </div>
                                                    </div>

                                                    <span id="too-length" v-if="isPictureTooLength(item)">
                                                    <div class="uk-margin-small-top"/>
                                                    <InfoBox mode="warning">
                                                        {{
                                                            $t('picture.length.exceeds.max.length.message', {
                                                                max: getArticleByID(item.articleID).maxLength,
                                                                diff: formatMeasurement(item.measurementValue - getArticleByID(item.articleID).maxLength),
                                                                measurementUnit: getInfos.measurementUnit,
                                                            })
                                                        }}
                                                    </InfoBox>
                                                    <div class="uk-margin-top"/>
                                                </span>
                                                </div>

                                                <div>
                                                    <div class="uk-grid-small" uk-grid>
                                                        <div class="uk-width-expand" uk-leader>
                                                            {{ $t('price') }}
                                                        </div>
                                                        <div>{{
                                                                getNumberWithCurrency(getCostsByAmountAndArticleId(item))
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                    <hr :id="'picture-hr-'+item.originKey"
                                        v-if="false"
                                        class="uk-hr hr-dashed uk-margin-medium-top uk-margin-medium-bottom"/>

                                </div>
                            </div>
                        </div>


                        <div v-if="isTerminalMode">

                            <div class="uk-width-expand" v-if="terminalSources === null">
                                <div class="uk-flex uk-flex-center uk-flex-middle">
                                    <div uk-spinner/>
                                </div>
                            </div>
                            <div v-else-if="terminalSources.length === 0"
                                 class="uk-width-expand">
                                <InfoBox mode="danger" icon="warning">
                                    <Translate translation-key="terminal.no.source"/>

                                </InfoBox>
                            </div>
                            <div v-else
                                 class="uk-grid-small uk-flex uk-flex-center" uk-grid>
                                <div :class="computedTerminalSourcesWidth"
                                     v-for="terminalSource in terminalSources" :key="terminalSource">
                                    <span v-on:click="selectTerminalSource(terminalSource)">
                                        <Card is-dark
                                              class="uk-text-center uk-card-hover cursor-pointer"
                                              mode="default">
                                            <Icon :ratio="2" :icon="getIconByName(terminalSource)"/>

                                            <div class="action-name uk-margin-small-top">
                                                <Translate :translation-key="terminalSource"/>
                                            </div>
                                        </Card>
                                    </span>
                                </div>
                            </div>


                            <div :id="modalIdTerminalSelect"
                                 class="uk-modal-full"
                                 uk-modal>
                                <div class="uk-modal-dialog">

                                    <div class="uk-modal-header">

                                        <ul uk-switcher="animation: uk-animation-fade"
                                            class="uk-child-width-expand" id="sources">
                                            <li v-for="tab in terminalSources" :key="tab"
                                                :class="tab === selectedTerminalSource ? 'uk-active' : ''"
                                                v-on:click="selectTerminalSource(tab)">
                                                <a href="#">
                                                    <Icon :icon="getIconByName(tab)"/>
                                                    <Translate :translation-key="tab"/>
                                                </a>
                                            </li>
                                        </ul>

                                        <ul class="uk-breadcrumb">
                                            <li v-for="(dir, key) in partedTerminalPaths"
                                                v-on:click="selectDir(key)"
                                                :key="'d'+dir">
                                                <a href="#" :uk-tooltip="'title:'+dir">{{ getSlimDirName(dir) }}</a>
                                            </li>
                                        </ul>

                                    </div>

                                    <div class="uk-modal-body" uk-overflow-auto>


                                        <ul class="uk-switchers uk-padding-remove-left">
                                            <li v-for="terminalSource in [selectedTerminalSource]"
                                                :key="terminalSource">
                                                <div v-if="terminalFilesError"
                                                     class="terminal-content-loading device-in-info uk-flex uk-flex-center uk-flex-middle">
                                                    <Translate translation-key="medium.missing.message"
                                                               :parameter="{x: $t(selectedTerminalSource)}"/>
                                                </div>
                                                <div
                                                    v-else-if="terminalFilesLoading || terminalSource !== selectedTerminalSource"
                                                    class="terminal-content-loading uk-flex uk-flex-center uk-flex-middle">
                                                    <div class="device-in-info" uk-spinner/>
                                                </div>
                                                <div v-else>

                                                    <div uk-grid
                                                         class="uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-2 terminal-content">

                                                        <div v-if="terminalFilesDir !== ''">
                                                            <div
                                                                class="uk-card uk-card-default uk-card-hover cursor-pointer">
                                                                <div v-on:click="backTerminalFolder()"
                                                                     class="uk-height-small uk-text-center folder-struct uk-padding-small">
                                                                    <Icon icon="arrow-left" :ratio="2.5"/>
                                                                    <div
                                                                        class="action-name uk-margin-small-top folder-name">
                                                                        <Translate translation-key="back"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-for="(terminalFile, key) in terminalFiles"
                                                             :key="'tf'+key">
                                                            <div
                                                                class="uk-card uk-card-default uk-card-hover cursor-pointer">

                                                                <div v-if="terminalFile.isDir"
                                                                     v-on:click="selectTerminalFolder(terminalFile.path, terminalFile.name)"
                                                                     class="uk-height-small uk-text-center folder-struct uk-padding-small">
                                                                    <Icon icon="folder" :ratio="2.5"/>

                                                                    <div :uk-tooltip="'title:'+terminalFile.name"
                                                                         class="action-name uk-margin-small-top folder-name">
                                                                        {{ terminalFile.name }}
                                                                    </div>
                                                                </div>

                                                                <div v-else
                                                                     v-on:click="toggleFileSelect(key)"
                                                                     class="uk-cover-container uk-height-small">

                                                                    <div
                                                                        class="uk-flex uk-flex-center uk-flex-middle image-item">
                                                                        <img
                                                                            uk-img
                                                                            class="image-item-struct"
                                                                            :src="apiUrlTerminal+'/thumbnails/'+terminalFile.device +terminalFile.path+'/'+terminalFile.name"
                                                                            :alt="terminalFile.name"/>
                                                                    </div>

                                                                    <div v-if="isFileSelected(key)"
                                                                         class="uk-overlay uk-overlay-primary uk-position-cover">
                                                                        <div
                                                                            class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
                                                                            <Icon class="uk-form-success"
                                                                                  icon="check"
                                                                                  :ratio="1.5"/>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </li>
                                        </ul>

                                    </div>

                                    <div class="uk-modal-footer">
                                        <div class="uk-grid uk-child-width-expand" uk-grid>
                                            <div>
                                                <Button mode="default"
                                                        is-max
                                                        has-action
                                                        :action="cancelSelection"
                                                        is-large>
                                                    <Translate translation-key="cancel"/>
                                                </Button>
                                            </div>
                                            <div>
                                                <Button mode="default"
                                                        is-max
                                                        has-action
                                                        :action="selectAllInFolder"
                                                        is-large>
                                                    <Translate translation-key="select.all"/>
                                                </Button>
                                            </div>
                                            <div>
                                                <Button mode="primary"
                                                        is-max
                                                        has-action
                                                        :is-loading="loadingFetch"
                                                        :action="processSelectedPictures"
                                                        is-large>
                                            <span v-if="selectedTerminalFiles.length>0">
                                                {{ selectedTerminalFiles.length }}
                                            </span>
                                                    <Translate translation-key="x.add"
                                                               :parameter="{x: $t(selectedTerminalFiles.length ===1 ?'picture' : 'pictures')}"/>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div v-else>

                            <div :class="pictures.length > 0 ? '' : ''">
                                <div v-if="pictures.length < getInfos.maxPicturesPerOrder" id="pictures-upload-2"
                                     class="js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer"
                                     v-on:click="triggerInputDialog()">
                                    <div>
                                        <Icon :ratio="2.2" class="upload-icon" icon="cloud-upload"/>
                                    </div>

                                    <div v-if="isDeviceMobile && isUploadInProgress">
                                        <span class="uk-text-middle">{{ $t('upload.in.progress.hint') }}</span>
                                    </div>
                                    <div v-else>
                                            <span class="uk-text-middle">{{
                                                    $t('x.drop.or.', {x: $t('pictures')})
                                                }}</span>
                                        <span class="uk-link uk-text-middle custom-link">{{ $t('select') }}</span>

                                        <div>
                                            <Translate translation-key="max"/>
                                            {{ getInfos.maxPicturesPerOrder }}
                                            <Translate translation-key="pictures"/>
                                        </div>
                                    </div>

                                    <div v-if="picturesError" class="uk-margin-top"/>
                                    <LabelWithError v-if="picturesError" :error="picturesError"
                                                    :label="$t('pictures')"/>
                                </div>
                                <InfoBox v-else mode="warning">
                                    {{ $t('pictures.max.reached', {x: getInfos.maxPicturesPerOrder}) }}
                                </InfoBox>
                            </div>
                        </div>


                        <div class="uk-margin-medium-bottom"/>
                        <div class="uk-child-width-expand" uk-grid>
                            <div v-if="isTerminalMode">
                                <Button :action="reload" :text="$t('cancel')" has-action is-large is-max
                                        mode="default"/>
                            </div>
                            <div>
                                <Button :action="validatePictures" :text="$t('next')" has-action is-large is-max
                                        mode="primary"/>
                            </div>
                        </div>
                    </li>

                    <li>
                        <span v-for="(inputField, key) in contactFields" :key="'i'+inputField.name">
                            <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                                       :autofocus="inputField.autofocus" :field-key="key"
                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                       :icon="inputField.icon"
                                       :is-optional="inputField.optional"
                                       :label="inputField.label"
                                       :name="inputField.name"
                                       :on-enter="validateContactDetails"
                                       :rules="inputField.rules"
                                       :type="inputField.type"
                                       :validate="triggerContactFields"
                                       @setValid="setValidContactFields"
                                       :has-keyboard="isTerminalMode"
                            />
                            <div v-if="key < contactFields.length-1" class="uk-margin-bottom"/>
                        </span>

                        <div v-if="getInfos.mandatoryFields.includes('address')">
                            <div class="uk-margin-bottom"/>
                            <span v-for="(inputField, key) in addressFields" :key="'ia'+inputField.name">
                            <TextField v-model="inputField.value"
                                       :autocomplete="inputField.autocomplete"
                                       :autofocus="inputField.autofocus" :field-key="key"
                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                       :icon="inputField.icon"
                                       :is-optional="inputField.optional"
                                       :label="inputField.label"
                                       :name="inputField.name"
                                       :rules="inputField.rules"
                                       :type="inputField.type"

                                       :validate="triggerContactFields"
                                       :on-enter="validateContactDetails"
                                       @setValid="setValidAddressFields"
                            />
                            <div v-if="key < contactFields.length-1" class="uk-margin-bottom"/>
                        </span>

                            <div class="uk-margin-bottom"/>
                            <LabelWithError :label="$t('country')+'*'"/>
                            <form autocomplete="off">
                                <select v-model="country"
                                        name="country"
                                        :disabled="getInfos.shippingCountries.length===1"
                                        class="uk-select uk-input-styled">
                                    <option v-for="option in getInfos.shippingCountries"
                                            :key="option"
                                            :value="option">{{ $t(option) }}
                                    </option>
                                </select>
                            </form>
                        </div>

                        <div class="uk-margin-medium-bottom"/>
                        <div class="uk-child-width-expand" uk-grid>
                            <div>
                                <a href="#" uk-tab-item="previous" v-on:click="refreshCroppeds">
                                    <Button :text="$t('previous')" is-large is-max mode="default"/>
                                </a>
                            </div>
                            <div>
                                <Button :action="validateContactDetails" :text="$t('next')" has-action is-large
                                        is-max
                                        mode="primary"/>
                            </div>
                        </div>

                    </li>
                    <li>
                        <div class="section-title">{{ $t('pictures') }}</div>

                        <div v-for="item in sortedPictures" :key="'p'+item.originKey" class=""
                             uk-grid>
                            <div class="uk-width-medium@s uk-width-small">
                                <div class="uk-inline uk-transition-toggle image-struct">

                                    <img v-if="item.thumbnailCroppedUrl" :src="item.thumbnailCroppedUrl"/>
                                    <div v-else
                                         class="uk-width-medium uk-height-small thumbnail-small uk-flex uk-flex-center"
                                         uk-spinner/>

                                </div>
                            </div>
                            <div class="uk-width-expand">
                                <div class="uk-grid-small uk-child-width-1-1" uk-grid>

                                    <div class="uk-width-auto@s uk-width-1-1">
                                        <span class="uk-badge badge">{{ item.amount }}x</span>
                                    </div>
                                    <div class="uk-width-auto@s uk-width-1-1">
                                        {{ getFormatByArticleID(item.articleID, true) }} ({{
                                            getPriceByArticleID(item.articleID, item.amount)
                                        }}{{
                                            getArticleByID(item.articleID).isHeightVariable ? ' ' + $t('per.x', {x: $t(getInfos.measurementUnit)}) : ''
                                        }}{{
                                            getArticleByID(item.articleID).isHeightVariable && getArticleByID(item.articleID).minPrice ? ', ' + $t('min') + ' ' + getNumberWithCurrency(getArticleByID(item.articleID).minPrice) : ''
                                        }})
                                    </div>

                                    <div v-if="isVariableHeight(item.originKey)">
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-width-expand" uk-leader>
                                                {{ $t('pictureLength') }}
                                                ({{ $t(getPictureFormatName(item.originKey) + '.format') }})
                                            </div>
                                            <div>
                                                <div v-if="item.measurementValue===0" uk-spinner/>
                                                <span v-else>
                                                    {{
                                                        formatMeasurement(item.measurementValue)
                                                    }} {{ $t(getInfos.measurementUnit) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-width-expand" uk-leader>
                                                {{ $t('price') }}
                                            </div>
                                            <div>{{
                                                    getNumberWithCurrency(getCostsByAmountAndArticleId(item))
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="item.error">
                                        <Icon class="uk-margin-small-right" icon="warning"/>
                                        {{ $t('upload.error') }}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="uk-margin-medium-bottom"/>
                        <div class="section-title">{{ $t('contact.details') }}</div>
                        <div v-for="inputField in contactFields" :key="'c'+inputField.name">
                            {{ inputField.value }}
                        </div>

                        <div v-if="getInfos.mandatoryFields.includes('address')">
                            <div v-for="inputField in addressFields" :key="'ca'+inputField.name">
                                {{ inputField.value }}
                            </div>
                            <div>{{ $t(country) }}</div>
                        </div>

                        <div class="uk-margin-medium-bottom"/>
                        <TextArea v-model="comments" :is-disabled="successOrder || loadingOrder" is-label-large
                                  label="comments"
                                  name="comments"
                                  :has-keyboard="isTerminalMode"
                                  rules="max:4096"/>


                        <div class="uk-margin-medium-bottom"/>
                        <div class="section-title">{{ $t('payment.method') }}</div>

                        <div class="uk-grid-small uk-child-width-1-2" uk-grid>
                            <div v-for="method in getPaymentMethodsSource()" :key="method">

                                <span v-on:click="setPaymentMethod(method)">
                                    <LabelSelect :is-disabled="successOrder || loadingOrder" with-line-height
                                                 :is-selected="paymentMethod === method">
                                        <div class="uk-text-center paper-text">
                                            <Icon class="icon-distance" :icon="getIconByName(method)"/>
                                            {{ $t(method) }}
                                        </div>
                                    </LabelSelect>
                                </span>

                            </div>
                        </div>

                        <div class="uk-margin-top" v-if="false && paymentMethod==='online'">
                            <stripe-element-payment
                                v-if="paymentKey"
                                ref="paymentRef"
                                :pk="getInfos.stripe.key"
                                :elements-options="elementsOptions"
                            />
                        </div>

                        <div :class="isAddressRequired ? '' : 'uk-hidden'">
                            <div class="uk-margin-medium-bottom"/>
                            <div class="section-title">{{ $t('address') }}</div>

                            <div class="uk-grid-small" uk-grid>
                                <div v-for="(inputField, key) in addressFields" :key="'i'+inputField.name"
                                     :class="key > 0 ? 'uk-width-1-2@s uk-width-1-1' : 'uk-width-1-1'">
                                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                                               :autofocus="inputField.autofocus" :field-key="key"
                                               :hasPasswordMeter="inputField.hasPasswordMeter"
                                               :icon="inputField.icon"
                                               :is-optional="inputField.optional"
                                               :label="inputField.label"
                                               :name="inputField.name"
                                               :on-enter="complete"
                                               :rules="inputField.rules"
                                               :type="inputField.type"
                                               :disabled="successOrder || loadingOrder"

                                               :validate="triggerAddressFields"
                                               @setValid="setValidAddressFields"
                                    />
                                </div>

                                <div class="uk-width-1-1">
                                    <LabelWithError :label="$t('country')+'*'"/>
                                    <form autocomplete="off">
                                        <select v-model="country"
                                                name="country"
                                                :disabled="getInfos.shippingCountries.length===1"
                                                class="uk-select uk-input-styled">
                                            <option v-for="option in getInfos.shippingCountries"
                                                    :key="option"
                                                    :value="option">{{ $t(option) }}
                                            </option>
                                        </select>
                                    </form>
                                </div>

                            </div>

                        </div>


                        <div class="uk-margin-medium-bottom"/>
                        <div class="section-title">{{ $t('delivery.type') }}</div>

                        <div class="uk-grid-small uk-child-width-1-2" uk-grid>
                            <div v-for="method in getDeliveryTypeOptions" :key="method">

                                    <span v-on:click="setDeliveryTypes(method)">
                                        <LabelSelect :is-disabled="successOrder || loadingOrder" with-line-height
                                                     :is-selected="deliveryType === method">
                                            <div class="uk-text-center paper-text">
                                                <Icon class="icon-distance" :icon="getIconByName(method)"/>
                                                {{ $t(method) }}
                                            </div>
                                        </LabelSelect>
                                    </span>

                            </div>
                        </div>


                        <div class="uk-margin-medium-bottom"/>
                        <div class="section-title">{{ $t('price') }}</div>

                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-expand" uk-leader>
                                <span class="uk-badge badge">{{ getTotalSamplesCount }}</span>
                                {{ $t(getTotalSamplesCount === 1 ? 'picture' : 'pictures') }}
                            </div>
                            <div>{{ getNumberWithCurrency(getTotalSamplesPrice) }}</div>
                        </div>


                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-expand" uk-leader>
                                {{ $t('service.fee') }}

                                <span v-if="getFeeDiscountBySource() != null" class="graduated-prices">
                                    ({{ $t('dropped.at') }} {{
                                        getNumberWithCurrency(getFeeDiscountBySource())
                                    }}:
                                    <span v-if="isFeeDiscount">
                                        <Icon :ratio="0.8" icon="check"/>)
                                    </span>
                                    <span v-else>
                                        {{
                                            $t('left')
                                        }} {{ getNumberWithCurrency(getFeeDiscountBySource() - getTotalSamplesPrice) }})
                                    </span>
                                </span>
                            </div>
                            <div>{{ getNumberWithCurrency(getFee) }}</div>
                        </div>


                        <div class="uk-grid-small uk-margin-small-medium-top" uk-grid>
                            <div class="uk-width-expand" uk-leader>{{ $t('shipping.costs') }}</div>
                            <div>{{ getNumberWithCurrency(getShippingCosts) }}</div>
                        </div>
                        <div
                            v-if="false && isAddressRequired && getInfos.shippingGraduatedPrices && getInfos.shippingGraduatedPrices.length>0"
                            class="uk-margin-top uk-margin-medium-bottom">
                            <InfoBox class="graduated-prices">
                                <div v-for="(graduatedPrice, key) in getInfos.shippingGraduatedPrices"
                                     :key="key">
                                        <span v-if="graduatedPrice.amount-getTotalSamplesCount>0">
                                            {{
                                                $t('x.at.x.amount.left.x', {
                                                    price: getNumberWithCurrency(graduatedPrice.price),
                                                    amount: graduatedPrice.amount,
                                                    required: graduatedPrice.amount - getTotalSamplesCount,
                                                })
                                            }}
                                        </span>
                                    <span v-else>
                                            {{
                                            $t('x.at.x.amount',
                                                {
                                                    price: getNumberWithCurrency(graduatedPrice.price),
                                                    amount: graduatedPrice.amount,
                                                })
                                        }}
                                            <Icon :ratio="0.8" icon="check"/>
                                        </span>

                                </div>
                            </InfoBox>
                        </div>


                        <div class="uk-grid-small uk-margin-small-medium-top total-costs-text" uk-grid>
                            <div class="uk-width-expand" uk-leader>{{ $t('total') }}</div>
                            <div>{{ getTotal }}</div>
                        </div>
                        <div class="vat">
                            {{ $t('vat.message', {vat: getInfos.vat}) }}
                        </div>


                        <div class="uk-margin-medium-bottom"/>
                        <div class="section-title">{{ $t('termsAndCondition.and.dataPrivacy') }}</div>
                        <Checkbox v-model="confirmTerms" :disabled="successOrder || loadingOrder"
                                  :is-disabled="successOrder || loadingOrder"
                                  :validate="triggerTerms"
                                  label="termsAndConditions.confirm" name="terms" rules="required"
                                  @setValid="setTermsValid"/>

                        <div v-if="isUploadInProgress" class="uk-margin-medium-bottom"/>
                        <InfoBox v-if="isUploadInProgress" class="uk-animation-fade" mode="warning">
                            {{ $t('upload.in.progress') }}

                            <div class="uk-margin-top"/>
                            <progress :value="getUnifiedUploadPercentage" class="uk-progress" max="100"/>
                            <div class="upload-status uk-text-center">
                                {{ $t('upload.status', {x: getUnifiedUploadPercentage}) }}
                            </div>
                        </InfoBox>

                        <div v-if="isAnyUploadError" class="uk-margin-medium-bottom"/>
                        <InfoBox v-if="isAnyUploadError" class="uk-animation-fade" mode="danger">
                            {{ $t('any.pictures.error') }}

                            <div class="uk-margin-top"/>
                            <Button :action="retryFailedPictures" :text="$t('retry')" has-action is-large
                                    mode="primary"/>
                        </InfoBox>

                        <div v-if="!successOrder" class="uk-margin-medium-bottom"/>
                        <div v-if="!successOrder" class="uk-child-width-expand@m uk-child-width-1-1" uk-grid>
                            <div class="uk-visible@m">
                                <a href="#" uk-tab-item="previous">
                                    <Button :text="$t('previous')" is-large is-max mode="default"/>
                                </a>
                            </div>
                            <div>
                                <Button :action="complete" :is-disabled="isUploadInProgress || isAnyUploadError"
                                        :is-loading="loadingOrder"
                                        :text="$t('paid.order')" has-action
                                        is-large is-max
                                        mode="success"/>
                            </div>
                            <div class="uk-hidden@m">
                                <a href="#" uk-tab-item="previous">
                                    <Button :text="$t('previous')" is-large is-max mode="default"/>
                                </a>
                            </div>
                        </div>

                        <div v-if="errorOrder" class="uk-margin-medium-bottom"/>
                        <ErrorMessage :custom-errors="[{code: 408, message: $t('session.timed.out')}]"
                                      :error="errorOrder"/>

                        <div v-if="successOrder" class="uk-margin-medium-bottom"/>
                        <SuccessMessage :success="successOrder">
                            {{ $t('paid.order.success') }}

                            <div class="uk-margin-medium-bottom"/>
                            <Button :action="reload" :text="$t('order.more.pictures')" has-action is-large is-max
                                    mode="primary"/>
                        </SuccessMessage>
                    </li>
                </ul>


                <TerminalAdsFooter v-if="isTerminalWithAds && getInfos.terminal.ads"
                                   class="uk-margin-medium-top"
                                   :terminal="getInfos.terminal"/>

                <TerminalAdsScreensaver v-if="isTerminalWithAds && successOrder"
                                        :pictures="sortedPictures"
                                        @ended="reload"
                                        :terminal="getInfos.terminal"/>

            </Card>

        </LoadingStruct>

        <Modal :id="modalIdSmartphone"
               :title="$t('smartphone.qrcode')">
            <div class="uk-text-center">
                <Description>
                    <Translate translation-key="smartphone.qrcode.message"/>
                </Description>
                <div ref="qrcode"/>
            </div>
        </Modal>

        <Modal :id="modalIdOrderSuccess" :title="$t('order.created.title')">
            <SuccessMessage without-animation success>{{ $t('paid.order.success') }}</SuccessMessage>

            <div class="uk-margin-medium-bottom"/>
            <Button :action="reload" :text="$t('order.more.pictures')" has-action is-large is-max mode="primary"/>

            <hr v-if="false">
            <Button v-if="false" :text="$t('close')" class="uk-modal-close" is-large is-max mode="default"/>

            <div v-if="!isApp">
                <PWAPromptForm v-if="getInfos && getInfos.name" :name="getInfos.name"
                               :prompt-installer-event="promptInstallerEvent"/>
                <PWAHintForm v-if="getInfos && getInfos.name" :name="getInfos.name"/>
            </div>

        </Modal>

    </div>
</template>

<script>

import QRCode from "easyqrcodejs";
import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import UIkit from "uikit";
import Icon from "@/components/generic/Icon";
import LabelWithError from "@/components/generic/LabelWithError";
import InfoBox from "@/components/generic/InfoBox";
import axios from "axios";
import {uuid} from 'vue-uuid';
import ErrorMessage from "@/components/generic/ErrorMessage";
import Label from "@/components/Label";
import SuccessMessage from "@/components/generic/SuccessMessage";
import TextArea from "@/components/TextArea";
import {isMobileOnly} from 'mobile-device-detect';
import LabelSelect from "@/components/LabelSelect";
import Vue from "vue";
import Modal from "../components/generic/Modal.vue";
import dayjs from "dayjs";
import Checkbox from "@/components/generic/Checkbox.vue";
import PWAHintForm from "@/components/PWAHintForm.vue";
import PWAPromptForm from "@/components/PWAPromptForm.vue";
import Translate from "@/components/Translate.vue";
import Screensaver from "@/components/Screensaver.vue";
import TerminalConnnectorNotRunnning from "@/components/TerminalConnnectorNotRunnning.vue";
import TerminalAdsHeader from "@/components/TerminalAdsHeader.vue";
import TerminalAdsFooter from "@/components/TerminalAdsFooter.vue";
import Description from "@/components/Description.vue";
import TerminalAdsScreensaver from "@/components/TerminalAdsScreensaver.vue";
import {StripeElementPayment} from '@vue-stripe/vue-stripe';

export default {
    name: 'Main',
    components: {
        StripeElementPayment,
        TerminalAdsScreensaver,
        Description,
        TerminalAdsFooter,
        TerminalAdsHeader,
        TerminalConnnectorNotRunnning,
        Screensaver,
        Translate,
        PWAPromptForm,
        PWAHintForm,
        Checkbox,
        Modal,
        LabelSelect,
        TextArea,
        SuccessMessage,
        Label,
        ErrorMessage,
        InfoBox,
        LabelWithError,
        Icon,
        Button,
        TextField,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            paymentKey: false,
            elementsOptions: {
                appearance: {}, // appearance options
            },

            loadingInfos: true,

            intervalThumbnail: 0,
            intervalUpload: 0,

            isDeviceMobile: isMobileOnly,

            modalIdOrderSuccess: "modal-order-success",
            modalIdTerminalConnectorChecker: "modal-terminal-connector-checker",
            modalIdTerminalSelect: "modal-terminal-select",
            modalIdSmartphone: "modal-terminal-smartphone",
            modalIdTerminalAdsScreensaver: "modal-terminal-ads-screensaver",

            terminalSources: null,
            selectedTerminalSource: null,
            terminalFilesLoading: true,
            terminalFiles: [],
            selectedTerminalFiles: [],
            terminalFilesDir: "",
            terminalFilesReplacing: false,
            terminalFilesReplacingInterval: 0,
            terminalFilesError: null,
            intervalPluginListener: 0,

            qrCode: null,

            loadingFetch: false,

            errorOrder: null,
            loadingOrder: false,
            successOrder: false,

            pictures: [],
            picturesError: null,

            pictureTypeErrors: [],
            pictureSizeErrors: [],
            pictureLimitErrors: [],

            sortModifier: 1,

            triggerContactFields: 0,
            contactFields: [
                {
                    value: "",
                    valid: false,

                    name: "name",
                    label: "name",
                    icon: "user",
                    rules: "required|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|emailV2",
                },
                {
                    value: "",
                    valid: true,

                    name: "telephone",
                    label: "telephone",
                    icon: "receiver",
                    rules: "max:80",
                },
            ],

            triggerAddressFields: 0,
            addressFields: [
                {
                    value: "",
                    valid: false,

                    name: "street",
                    label: "street",
                    icon: "home",
                    rules: "required|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "zip",
                    label: "zip",
                    icon: "location",
                    rules: "required|numeric|max:320",
                },
                {
                    value: "",
                    valid: false,

                    name: "city",
                    label: "city",
                    icon: "world",
                    rules: "required|max:320",
                },
            ],

            country: "",

            paymentMethod: "",
            deliveryType: "",
            requestToken: "",
            requestTokenHeaders: null,
            comments: "",

            unifiedFormat: "",
            unifiedId: "",
            unifiedPaper: "",
            successUnified: false,

            confirmTerms: false,
            triggerTerms: 0,
            termsValid: false,

            isApp: true,
            promptInstallerEvent: null,

            isTerminalConnectorModalShown: false,
        }
    },
    computed: {
        computedTerminalSourcesWidth() {
            const length = this.terminalSources.length;

            if (length === 7) {
                return 'uk-width-1-4';
            }

            if (length === 6) {
                return 'uk-width-1-3';
            }

            if (length === 5) {
                return 'uk-width-1-3';
            }

            return 'uk-width-expand';
        },
        partedTerminalPaths() {
            if (this.terminalFilesDir === "") {
                return [this.$t(this.selectedTerminalSource)];
            }

            let dirs = [];
            dirs.push(this.$t(this.selectedTerminalSource));

            let terminalDirs = this.terminalFilesDir;
            let splitted = terminalDirs.split("/");

            for (let i = 0, j = splitted.length; i < j; i++) {
                if (splitted[i] === "") {
                    continue;
                }

                dirs.push(splitted[i]);
            }

            return dirs;
        },
        sortedPictures() {
            let pictures = this.pictures;

            let parsed = [];
            for (let i = 0, j = pictures.length; i < j; i++) {
                pictures[i].originKey = i;
                parsed.push(pictures[i]);
            }

            return parsed.sort(this.picturestSortFunc);
        },
        isAddressRequired() {
            if (this.paymentMethod === "invoice") {
                return true;
            }

            return false;
        },
        getDeliveryTypeOptions() {
            let options = [];
            options.push("pickup");

            if (this.paymentMethod === "shop") {
                return options;
            }

            if (this.getInfos.isShippingAllowed && this.paymentMethod === "invoice") {
                options.push("shipping");
            }

            return options;
        },
        getPicturesWithErrorInfo() {
            let pictures = this.pictures;

            /*  let picturesWithoutError = [];
              for (let i = 0, j = pictures.length; i < j; i++) {
                  if (pictures[i].error === null) {
                      picturesWithoutError.push(pictures[i]);
                  }
              }*/

            return pictures;
        },
        getFormats() {
            let formats = [];

            let articles = this.getInfos.articles;

            if (!articles) {
                return formats;
            }

            articles = articles.sort(this.articleFormatSortFunc);

            for (let i = 0, j = articles.length; i < j; i++) {
                let format = this.getArticleFormat(articles[i]);
                if (!formats.includes(format)) {
                    formats.push(format);
                }
            }

            return formats;
        },
        getPapers() {
            let papers = [];

            let articles = this.getInfos.articles;
            for (let i = 0, j = articles.length; i < j; i++) {
                if (!papers.includes(articles[i].paper)) {
                    papers.push(articles[i].paper);
                }
            }

            return papers;
        },
        getTotalSamplesCount() {
            let total = 0;

            let pictures = this.pictures;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += pictures[i].amount;
            }

            return total;
        },
        getTotalSamplesPrice() {
            let total = 0;

            let pictures = this.pictures;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += this.getCostsByAmountAndArticleId(pictures[i]);
            }

            return total;
        },
        getFee() {
            let total = this.getTotalSamplesPrice;
            let fee = this.getFeeBySource();
            let feeDiscount = this.getFeeDiscountBySource();

            if (feeDiscount != null && total >= feeDiscount) {
                fee = 0.0;
            }

            return fee;
        },
        getShippingCosts() {
            let samples = this.getTotalSamplesCount;

            if (this.deliveryType !== "shipping") {
                return 0.0;
            }

            let shippingGraduatedPrices = this.getSortedGraduatedPrices(this.getInfos.shippingGraduatedPrices, true);
            for (let i = 0, j = shippingGraduatedPrices.length; i < j; i++) {
                if (samples >= shippingGraduatedPrices[i].amount) {
                    return shippingGraduatedPrices[i].price;
                }
            }

            return 0.0;
        },
        isFeeDiscount() {
            let total = this.getTotalSamplesPrice;
            let feeDiscount = this.getFeeDiscountBySource();

            if (feeDiscount != null && total >= feeDiscount) {
                return true;
            }

            return false;
        },
        getTotal() {
            let total = this.getTotalSamplesPrice;
            let fee = this.getFee;
            let shipping = this.getShippingCosts;

            return this.getNumberWithCurrency(total + fee + shipping);
        },
        isStartValid() {
            if (this.successOrder || this.loadingOrder) {
                return false;
            }

            return true;
        },
        isPicturesValid() {
            if (this.successOrder || this.loadingOrder) {
                return false;
            }

            if (this.pictures.length === 0) {
                return false;
            }

            if (this.picturesError !== null) {
                return false;
            }

            for (let i = 0, j = this.pictures.length; i < j; i++) {
                if (this.isPictureTooLength(this.pictures[i])) {
                    return false;
                }
            }

            return true;
        },
        getUnifiedUploadPercentage() {
            let pictures = this.pictures;
            let total = 0;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += pictures[i].uploadPercentage
            }

            let totalPercentage = total / pictures.length;
            return (Math.round(totalPercentage * 100) / 100).toFixed(0);
        },
        isUploadInProgress() {
            for (let i = 0, j = this.pictures.length; i < j; i++) {
                if (this.pictures[i].uploadPercentage !== 100) {
                    return true;
                }
            }

            return false;
        },
        isAnyUploadError() {
            for (let i = 0, j = this.pictures.length; i < j; i++) {
                if (this.pictures[i].error !== null) {
                    return true;
                }
            }

            return false;
        },
        isContactDetailsValid() {
            let picturesValid = this.isPicturesValid;
            if (picturesValid === false) {
                return false;
            }

            let contactFields = this.contactFields;

            for (let i = 0, j = contactFields.length; i < j; i++) {
                if (!contactFields[i].valid) {
                    return false;
                }
            }

            return true;
        },
    },
    mounted() {
        let self = this;
        window.onunload = function () {
            if (!self.successOrder) {
                self.deleteRequestToken();
            }
        }

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            self.isApp = false;
            self.promptInstallerEvent = e;
        });

        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari && !navigator.standalone) {
            this.isApp = false;
        }

        window.addEventListener('dragover', this.dragoverListener, false);

        let element1 = document.getElementById('pictures-upload-1');
        if (element1) {
            element1.addEventListener('drop', this.dropListener, false);
        }

        let element2 = document.getElementById('pictures-upload-2');
        if (element2) {
            element2.addEventListener('drop', this.dropListener, false);
        }

        // this.fetchBlob("http://localhost:8080/logo")
    },
    beforeRouteLeave(to, from, next) {
        if (this.isStayInForm()) {
            next(false);
            window.location = to.path;
        } else {
            next();
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);

        if (this.isTerminalMode) {
            this.getTerminalSources();

            let self = this;
            setInterval(function () {
                if (self.isStart && self.isTerminalConnectorRunning !== null) {

                    if (self.isTerminalConnectorRunning) {
                        if (self.isTerminalConnectorModalShown) {
                            self.isTerminalConnectorModalShown = false;
                            UIkit.modal("#" + self.modalIdTerminalConnectorChecker).hide();
                        }

                        self.getTerminalSources();

                    } else {
                        if (!self.isTerminalConnectorModalShown) {
                            self.isTerminalConnectorModalShown = true;
                            UIkit.modal("#" + self.modalIdTerminalConnectorChecker).show();
                        }

                    }

                }
            }, 500);
        }

        if (this.isTerminalWithAds) {
            this.$store.state.start = true;
        }

        dayjs.locale(this.$i18n.locale);
        this.receiveInfosLocale();
        this.paymentMethod = this.getPaymentMethodsSource()[0];
        this.deliveryType = this.getDeliveryTypeOptions[0];

        this.postRequestToken();

        this.thumbnailQueue();
        this.uploadQueue();

        let firstFormat = this.getFirstFormat();
        this.unifiedFormat = firstFormat;
        this.unifiedId = this.getFirstArticleIDByFormat(firstFormat)
    },
    methods: {
        getPaymentMethodsSource() {
            if (this.isTerminalMode) {
                return this.getInfos.terminal.paymentMethods
            }

            return this.getInfos.paymentMethods
        },
        getFeeBySource() {
            if (this.isTerminalMode) {
                return this.getInfos.terminal.fee
            }

            return this.getInfos.fee
        },
        getFeeDiscountBySource() {
            if (this.isTerminalMode) {
                return this.getInfos.terminal.feeDiscount
            }

            return this.getInfos.feeDiscount
        },
        isStayInForm() {
            let sessionInvalid = localStorage.getItem('session_invalid') !== null;

            return this.pictures.length > 0 && !this.successOrder && !sessionInvalid;
        },
        beforeWindowUnload(e) {
            if (this.isStayInForm()) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        },

        getSlimDirName(dir) {
            const limit = 20;
            if (dir.length <= limit) {
                return dir;
            }

            return dir.substring(0, limit - 3) + "...";
        },
        selectDir(key) {
            if (key === 0) {
                this.terminalFilesDir = "";
            } else {
                const dirs = this.partedTerminalPaths;

                let targetDir = "";
                for (let i = 1; i < key + 1; i++) {
                    if (i > 1) {
                        targetDir += "/"
                    }
                    targetDir += dirs[i];
                }
                this.terminalFilesDir = targetDir;
            }

            this.getTerminalFiles();
        },
        handleTokenExpiry() {
            localStorage.setItem('session_invalid', "true");
            this.reload();
        },
        screensaverStart() {
            this.postRequestToken();
        },
        getTerminalSources() {
            if (this.terminalSources && this.terminalSources.length > 0) {
                return;
            }

            let http = axios.create();
            http.get(this.apiUrlTerminal + '/sources').then(response => {
                this.terminalSources = response.data.sources ? response.data.sources : [];

                if (this.isTerminalWithAds) {
                    this.terminalSources.push('smartphone');
                    const options = {
                        text: window.location.origin,
                    }
                    this.qrCode = new QRCode(this.$refs.qrcode, options);
                }

                if (this.getInfos.terminal.QRCodeLink) {
                    this.terminalSources.push('smartphone');
                    const options = {
                        text: this.getInfos.terminal.QRCodeLink,
                    }
                    this.qrCode = new QRCode(this.$refs.qrcode, options);
                }

                this.receiveInfosLocale(response.data.token);

            }).catch((e) => {
                this.checkGenericError(e);

            });
        },
        replaceTerminalFiles(files) {
            this.terminalFilesReplacing = true;

            let self = this;
            this.terminalFilesReplacingInterval = setInterval(function () {
                for (let i = 0, j = self.terminalFiles.length; i < j; i++) {
                    if (self.terminalFiles[i].thumbnailing) {
                        return;
                    }
                }

                self.terminalFiles = files;
                self.terminalFilesReplacing = false;
                self.terminalFilesLoading = false;

                clearInterval(self.terminalFilesReplacingInterval);
            }, 100);
        },
        backTerminalFolder() {
            let parts = this.terminalFilesDir.split("/");

            parts.pop();
            this.terminalFilesDir = parts.join("/");

            this.getTerminalFiles();
        },
        selectTerminalFolder(terminalPath, terminalFolder) {
            let path = terminalPath + "/" + terminalFolder;
            this.terminalFilesDir = path.substring(1);
            this.getTerminalFiles();
        },
        toggleFileSelect(key) {
            if (this.isFileSelected(key)) {
                this.deselectFile(key);
                return;
            }

            this.selectFile(key);
        },
        selectFile(key) {
            const terminalFile = this.terminalFiles[key];
            this.selectedTerminalFiles.push(terminalFile);
        },
        deselectFile(key) {
            const selectedTerminalFiles = this.selectedTerminalFiles;
            const terminalFile = this.terminalFiles[key];

            const terminalFileId = terminalFile.device + terminalFile.path + terminalFile.name

            for (let i = 0, j = selectedTerminalFiles.length; i < j; i++) {
                let selectedTerminalFileId = selectedTerminalFiles[i].device + selectedTerminalFiles[i].path + selectedTerminalFiles[i].name;

                if (terminalFileId === selectedTerminalFileId) {
                    this.selectedTerminalFiles.splice(i, 1);
                    return;
                }
            }
        },
        processSelectedPictures() {
            this.loadingFetch = true;

            const selectedTerminalFiles = this.selectedTerminalFiles;
            for (let i = 0, j = selectedTerminalFiles.length; i < j; i++) {
                let terminalFile = selectedTerminalFiles[i];
                this.fetchBlob(this.apiUrlTerminal + '/files/' + terminalFile.device + terminalFile.path + '/' + terminalFile.name, terminalFile.name, selectedTerminalFiles.length - 1 === i);
            }

            this.selectedTerminalFiles = [];
        },
        selectAllInFolder() {
            for (let i = 0, j = this.terminalFiles.length; i < j; i++) {
                const terminalFile = this.terminalFiles[i];

                if (terminalFile.isDir) {
                    continue;
                }

                if (this.isFileSelected(i)) {
                    continue;
                }

                this.selectedTerminalFiles.push(terminalFile);
            }
        },
        cancelSelection() {
            this.selectedTerminalFiles = [];
            UIkit.modal("#" + this.modalIdTerminalSelect).hide();
        },
        isFileSelected(key) {
            const selectedTerminalFiles = this.selectedTerminalFiles;
            const terminalFile = this.terminalFiles[key];

            const terminalFileId = terminalFile.device + terminalFile.path + terminalFile.name

            for (let i = 0, j = selectedTerminalFiles.length; i < j; i++) {
                let selectedTerminalFileId = selectedTerminalFiles[i].device + selectedTerminalFiles[i].path + selectedTerminalFiles[i].name;

                if (terminalFileId === selectedTerminalFileId) {
                    return true;
                }
            }

            return false;
        },
        selectTerminalSource(terminalSource) {
            if (terminalSource === "smartphone") {
                UIkit.modal("#" + this.modalIdSmartphone).show();
                return;
            }

            this.selectedTerminalSource = terminalSource;
            this.terminalFilesDir = "";
            this.getTerminalFiles();

            UIkit.modal("#" + this.modalIdTerminalSelect).show();
            UIkit.tab("#sources").show(this.terminalSources.indexOf(terminalSource));
        },
        getTerminalFiles() {
            this.terminalFilesLoading = true;

            let http = axios.create();
            http.get(this.apiUrlTerminal + '/sources/' + this.selectedTerminalSource + '/' + this.terminalFilesDir).then(response => {
                clearInterval(this.intervalPluginListener);
                this.intervalPluginListener = 0;
                this.terminalFilesError = null;

                let terminalFiles = response.data;

                if (!terminalFiles) {
                    terminalFiles = [];
                }

                for (let i = 0, j = terminalFiles.length; i < j; i++) {
                    terminalFiles[i].file = null;
                    terminalFiles[i].thumbnail = null;
                    terminalFiles[i].thumbnailUrl = null;
                    terminalFiles[i].thumbnailing = false;
                }

                this.replaceTerminalFiles(terminalFiles);

            }).catch(e => {
                this.terminalFiles = [];
                this.terminalFilesLoading = false;
                this.terminalFilesError = e;
                this.terminalFilesDir = "";

                if (this.intervalPluginListener === 0) {
                    this.initPluginListener();
                }

            });
        },
        initPluginListener() {
            this.intervalPluginListener = setInterval(() => {
                this.getTerminalFiles();
            }, 500);
        },
        fetchBlob(url, name, last) {
            let self = this;
            axios({
                method: 'get',
                url: url,
                responseType: 'blob',
            }).then(function (response) {
                let file = new File([response.data], name);
                self.processFilesUpload([file]);

                if (last) {
                    self.loadingFetch = false;
                    self.selectedTerminalFiles.splice(0);
                    UIkit.modal("#" + self.modalIdTerminalSelect).hide();
                }
            })

        },
        addHighlight(key, clicked) {
            let id = 'crop-icon-' + key;
            let element = document.getElementById(id);
            element.classList.add("crop-icon-hovered");

            if (clicked) {
                setTimeout(function () {
                    element.classList.remove("crop-icon-hovered");
                }, 1000);
            }
        },
        removeHighlight(key) {
            let id = 'crop-icon-' + key;
            let element = document.getElementById(id);
            element.classList.remove("crop-icon-hovered");
        },
        rotateCroppingLeft(key) {
            this.$refs["cropper" + key][0].rotate(90);
        },
        rotateCroppingRight(key) {
            this.$refs["cropper" + key][0].rotate(-90);
        },
        rotateCropping(key) {
            Vue.set(this.pictures[key], "rotate", !this.pictures[key].rotate);

            this.$refs["cropper" + key][0].setCoordinates(
                [
                    ({imageSize}) => ({
                        width: imageSize.width,
                        height: imageSize.height,
                    }),
                    ({coordinates, imageSize}) => ({
                        left: imageSize.width / 2 - coordinates.width / 2,
                        top: imageSize.height / 2 - coordinates.height / 2,
                    }),
                ]
            );
        },
        resetCroppingByKey(key) {
            this.$refs['cropper' + key][0].setCoordinates([
                ({imageSize}) => ({
                    width: imageSize.width,
                    height: imageSize.height,
                }),
                ({coordinates, imageSize}) => ({
                    left: imageSize.width / 2 - coordinates.width / 2,
                    top: imageSize.height / 2 - coordinates.height / 2,
                }),
            ]);
        },
        refreshCroppeds() {
            return;
        },
        receiveInfosLocale(token) {
            this.loadingInfos = true;

            let http = axios.create();
            http.get(this.apiUrl + '/infos', {
                headers: {
                    "token": token,
                    'Content-Language': Vue.localStorage.get('language'),
                },

            }).then(response => {
                let data = response.data;
                this.$store.state.infos = data;

                if (data.mandatoryFields.includes("telephone")) {
                    this.contactFields[2].valid = false;
                    this.contactFields[2].rules = "required|max:80";
                }

                this.country = data.shippingCountries[0];
                if (data.shippingCountries.includes(data.country)) {
                    this.country = data.country;
                }

                this.loadingInfos = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.loadingInfos = false;

            });
        },
        setPaymentMethod(method) {
            if (this.successOrder || this.loadingOrder) {
                return;
            }

            this.paymentMethod = method;

            if (method === "shop") {
                this.deliveryType = "pickup";
            }

            if (method === "invoice") {
                this.country = this.getInfos.shippingCountries[0];
                if (this.getInfos.shippingCountries.includes(this.getInfos.country)) {
                    this.country = this.getInfos.country;
                }
            }

            if (method === "online" && !this.paymentKey) {
                this.postOrderRequestPayment();
            }
        },
        setDeliveryTypes(method) {
            if (this.successOrder || this.loadingOrder) {
                return;
            }

            this.deliveryType = method;
        },
        triggerInputDialog() {
            document.getElementById('pictures').click();
        },
        triggerReplaceDialog(key) {
            this.$refs['pictures-' + key].value = null;
            document.getElementById('pictures-' + key).click();
        },
        enableCropping(key) {
            for (let i = 0, j = this.pictures.length; i < j; i++) {
                Vue.set(this.pictures[i], "croppingEnabled", false)
            }

            Vue.set(this.pictures[key], "croppingEnabled", true)
        },
        disableCropping(key) {
            Vue.set(this.pictures[key], "croppingEnabled", false)
        },
        setUnifiedFormat() {
            let pictures = this.pictures;

            for (let i = 0, j = pictures.length; i < j; i++) {
                if (pictures[i].format === this.unifiedFormat && pictures[i].articleID === this.unifiedId) {
                    continue;
                }

                pictures[i].format = this.unifiedFormat;
                pictures[i].articleID = this.unifiedId;

                this.resetCroppingByKey(i);
                this.getAndSetMeasurement(i);
            }

            this.successUnified = true;

            UIkit.notification({
                message: this.$t('all.pictures.changed'),
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });
        },
        dragoverListener(e) {
            e.preventDefault();

            let element1 = document.getElementById('pictures-upload-1');
            element1.classList.add('dragover');

            let element2 = document.getElementById('pictures-upload-2');
            element2.classList.add('dragover');
        },
        dropListener(e) {
            e.preventDefault();

            let element1 = document.getElementById('pictures-upload-1');
            element1.classList.remove('dragover');

            let element2 = document.getElementById('pictures-upload-2');
            element2.classList.remove('dragover');

            let files = e.dataTransfer.files;

            /*
            if (files.length > this.getInfos.maxPicturesPerOrder) {
                this.picturesError = this.$t('pictures.max.reached.drop', {x: this.getInfos.maxPicturesPerOrder});
                return;
            }
             */

            this.processFilesUpload(files);
        },
        areCoordinatesEqual(oldCoordinates, newCoordinates) {
            if (!oldCoordinates) {
                return false;
            }

            if (oldCoordinates.height !== newCoordinates.height) {
                return false;
            }

            if (oldCoordinates.left !== newCoordinates.left) {
                return false;
            }

            if (oldCoordinates.top !== newCoordinates.top) {
                return false;
            }

            if (oldCoordinates.width !== newCoordinates.width) {
                return false;
            }

            return true;
        },
        handleCropperChange(key) {
            const {coordinates, canvas} = this.$refs["cropper" + key][0].getResult();

            if (!coordinates || (coordinates.height === 0 && coordinates.width === 0 && coordinates.left === 0 && coordinates.top === 0)) {
                return;
            }

            if (this.areCoordinatesEqual(this.pictures[key].coordinates, coordinates)) {
                return;
            }

            Vue.set(this.pictures[key], "coordinates", coordinates);

            this.getAndSetMeasurement(key)

            let self = this;
            canvas.toBlob(
                (blob) => {
                    Vue.set(self.pictures[key], "thumbnailCroppedUrl", self.getLocalFileUrl(blob));
                },
                self.pictures[key].file.type,
                1.0,
            );
        },
        getAndSetMeasurement(pictureKey) {
            let picture = this.pictures[pictureKey];

            let article = this.getArticleByID(picture.articleID);
            let originDimensions = this.getOriginDimensions(picture.coordinates, picture.ratio);

            let width = originDimensions.width;
            if (width > picture.width) {
                width = picture.width;
            }

            let height = originDimensions.height;
            if (height > picture.height) {
                height = picture.height;
            }

            let originWidth = width;
            let originHeight = height;
            if (originHeight > originWidth) {
                originWidth = height;
                originHeight = width;
            }

            const dpi = article.dpi;

            let measurementFactor = 1.0;
            if (this.getInfos.measurementUnit === "cm") {
                measurementFactor = 2.54;
            }

            let targetPixel = dpi / measurementFactor * (article.width);
            let newWidth = Math.ceil(originWidth * targetPixel / originHeight);
            let measurementValue = newWidth * measurementFactor / dpi;

            let measurementValueRounded = Math.round(measurementValue * 10) / 10;

            Vue.set(this.pictures[pictureKey], "measurementValue", measurementValueRounded);
        },
        isVariableHeight(key) {
            let item = this.pictures[key];
            let article = this.getArticleByID(item.articleID);

            return article.isHeightVariable
        },
        getPictureFormatName(key) {
            let item = this.pictures[key];

            if (item.coordinates) {
                if (item.coordinates.height > item.coordinates.width) {
                    return "vertical";
                }

                return "horizontal";
            }

            if (item.height > item.width) {
                return "vertical";
            }

            return "horizontal";
        },
        getCropperRatioByPictureKey(key) {
            let item = this.pictures[key];
            let article = this.getArticleByID(item.articleID);

            if (article.isHeightVariable) {
                return null;
            }

            if (item.rotate) {
                return this.rotatedCropperRatio(article, item);
            }

            // hochformat
            if (item.height > item.width) {
                if (article.height > article.width) {
                    return article.width / article.height;
                }

                return article.height / article.width;
            }

            // querformat
            if (article.height > article.width) {
                return article.height / article.width;
            }

            return article.width / article.height;
        },
        rotatedCropperRatio(article, item) {
            // hochformat
            if (item.height > item.width) {
                if (article.height > article.width) {
                    return article.height / article.width;
                }

                return article.width / article.height;
            }

            // querformat
            if (article.height > article.width) {
                return article.width / article.height;
            }

            return article.height / article.width;
        },
        postOrderRequestPayment() {
            if (this.paymentKey) {
                return;
            }

            let platform = "browser";
            if (this.isApp) {
                platform = "app";
            }

            if (this.isTerminalMode) {
                platform = "terminal";
            }

            let http = axios.create();
            http.post(this.apiUrl + '/requests/' + this.requestToken + '/payment', {
                platform,

            }).then(response => {
                this.elementsOptions.clientSecret = response.data.key;
                this.paymentKey = true;

            }).catch(() => {
            });
        },
        deleteOrderRequestItem(fileStruct) {
            const config = {
                headers: {
                    'index': fileStruct.id,
                },
            }

            this.requestTokenHeaders = null;

            let http = axios.create();
            http.patch(this.apiUrl + '/requests/' + this.requestToken, null, config).then(response => {
                this.requestTokenHeaders = response.headers;

            }).catch(() => {
            });
        },
        deleteRequestToken() {
            let http = axios.create();
            http.delete(this.apiUrl + '/requests/' + this.requestToken).then(() => {
            }).catch(() => {
            });
        },
        postRequestToken() {
            this.requestTokenHeaders = null;

            let http = axios.create();
            http.post(this.apiUrl + '/requests').then(response => {
                this.requestToken = response.data.token;
                this.requestTokenHeaders = response.headers;

            }).catch(e => {
                this.checkGenericError(e);

            });
        },
        retryFailedPictures() {
            for (let i = 0, j = this.pictures.length; i < j; i++) {
                if (this.pictures[i].error !== null) {
                    this.pictures[i].error = null;
                    this.pictures[i].thumbnail = null;
                    this.pictures[i].thumbnailUrl = null;
                    this.pictures[i].coordinates = null;
                    this.pictures[i].thumbnailCroppedUrl = null;
                    this.pictures[i].uploadPercentage = 0;
                    this.pictures[i].isDone = false;
                    this.pictures[i].inProgress = false;
                }
            }
        },
        clonePicture(key) {
            if (this.pictures[key].cloneInProgress) {
                return;
            }

            if (this.pictures[key].uploadPercentage === 100) {
                this.cloneRequestToken(key);
                return;
            }

            const newId = uuid.v4();
            this.clonePictureSuccess(key, newId, false, this.pictures[key].thumbnailUrl !== null);
            this.pictures[key].cloneInProgress = false;
        },
        cloneRequestToken(key) {
            this.pictures[key].cloneInProgress = true;

            let self = this;
            this.requestTokenHeaders = null;
            const newId = uuid.v4();

            let http = axios.create();
            http.put(this.apiUrl + '/requests/' + self.requestToken + '/clone', {
                sourceId: self.pictures[key].id,
                destinationId: newId,

            }).then(response => {
                self.clonePictureSuccess(key, newId, true, true);
                self.pictures[key].cloneInProgress = false;

                this.requestTokenHeaders = response.headers;

            }).catch(e => {
                self.pictures[key].cloneInProgress = false;

                self.checkGenericError(e);

            });
        },
        retryUpload(fileStruct) {
            fileStruct.error = null;
            fileStruct.thumbnail = null;
            fileStruct.thumbnailUrl = null;
            fileStruct.coordinates = null;
            fileStruct.thumbnailCroppedUrl = null;
            fileStruct.uploadPercentage = 0;
            fileStruct.isDone = false;
            fileStruct.inProgress = false;
        },
        putRequestToken(fileStruct) {
            let self = this;

            let formData = new FormData();
            formData.append("file", fileStruct.file, fileStruct.file.name);
            formData.append("meta", fileStruct.thumbnail, fileStruct.file.name);

            const config = {
                onUploadProgress: function (progressEvent) {
                    fileStruct.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                },
                headers: {
                    'index': fileStruct.id,
                },
            }
            this.requestTokenHeaders = null;
            let http = axios.create();
            http.put(this.apiUrl + '/requests/' + self.requestToken, formData, config).then(response => {
                fileStruct.uploadPercentage = 100;

                fileStruct.inProgress = false;
                fileStruct.isDone = true;

                this.requestTokenHeaders = response.headers;

            }).catch((e) => {
                self.checkGenericError(e);
                fileStruct.uploadPercentage = 0;
                fileStruct.error = e;
                fileStruct.inProgress = false;
                fileStruct.isDone = true;

            });
        },
        manipulateAmount(key, manipulator) {
            this.pictures[key].amount += manipulator;
            this.validateAmount(key);
        },
        validateAmount(key) {
            if (!this.pictures[key].amount || this.pictures[key].amount === 0) {
                this.pictures[key].amount = 1;
            }

            if (this.pictures[key].amount < 0) {
                this.pictures[key].amount *= -1;
            }
        },
        getGroupedPicturesAmountByArticleID(articleID) {
            let amount = 0;

            let pictures = this.pictures;
            for (let i = 0, j = pictures.length; i < j; i++) {
                if (pictures[i].articleID === articleID) {
                    amount += pictures[i].amount;
                }
            }

            return amount;
        },
        getSortedGraduatedPrices(graduatedPrices, invert = false) {
            let graduatedPricesParsed = JSON.parse(JSON.stringify(graduatedPrices));

            function sortNodes(a, b) {
                if (a.amount < b.amount) return -1;
                if (a.amount > b.amount) return 1;
                return 0;
            }

            function sortNodesInvert(a, b) {
                if (a.amount > b.amount) return -1;
                if (a.amount < b.amount) return 1;
                return 0;
            }

            if (invert) {
                return graduatedPricesParsed.sort(sortNodesInvert);
            }

            return graduatedPricesParsed.sort(sortNodes);
        },
        getCostsByAmountAndArticleId(item) {
            let articleID = item.articleID;
            let amount = item.amount;

            let article = this.getArticleByID(articleID);
            let price = article.price;

            if (article.graduatedPrices) {
                let graduatedPrices = this.getSortedGraduatedPrices(article.graduatedPrices);
                let picturesAmount = this.getGroupedPicturesAmountByArticleID(articleID)

                for (let k = 0, l = graduatedPrices.length; k < l; k++) {
                    if (picturesAmount >= graduatedPrices[k].amount) {
                        price = graduatedPrices[k].price;
                    }
                }
            }

            if (article.isHeightVariable) {
                let measurementValue = item.measurementValue;
                let measurementValueRounded = Math.round(measurementValue);

                price = price * measurementValueRounded

                price = Math.round(price * 10) / 10;

                if (article.minPrice && article.minPrice > price) {
                    price = article.minPrice
                }
            }

            return amount * price;
        },
        getPriceByArticleID(articleID) {
            let article = this.getArticleByID(articleID);

            let price = article.price;
            if (article.graduatedPrices) {
                let graduatedPrices = this.getSortedGraduatedPrices(article.graduatedPrices);
                let picturesAmount = this.getGroupedPicturesAmountByArticleID(articleID)

                for (let k = 0, l = graduatedPrices.length; k < l; k++) {
                    if (picturesAmount >= graduatedPrices[k].amount) {
                        price = graduatedPrices[k].price;
                    }
                }
            }

            return this.getNumberWithCurrency(price);
        },
        getFormatByArticleID(articleID, isShort = false) {
            let article = this.getArticleByID(articleID);
            return this.getPaperName(article.paper) + " " + this.getArticleFormat(article, isShort);
        },
        getArticleByID(articleID) {
            let articles = this.getInfos.articles;
            for (let i = 0, j = articles.length; i < j; i++) {
                if (articles[i].id === articleID) {
                    return articles[i];
                }
            }
        },
        getPictureWidthLowDiff(picture) {
            let article = this.getArticleByID(picture.articleID);
            let rec = this.getPixelFromMeasurement(article.width, article.dpi);

            if (picture.width < rec) {
                return parseInt(rec - picture.width);
            }

            return 0;
        },
        getPictureHeightLowDiff(picture) {
            let article = this.getArticleByID(picture.articleID);
            let rec = this.getPixelFromMeasurement(article.height, article.dpi);

            if (picture.height < rec) {
                return parseInt(rec - picture.height);
            }

            return 0;
        },
        getPapersByFormat(format) {
            let papers = [];

            let articles = this.getInfos.articles;
            for (let i = 0, j = articles.length; i < j; i++) {

                let rowFormat = this.getArticleFormat(articles[i]);

                if (format && format !== rowFormat) {
                    continue;
                }

                let priceString = this.getNumberWithCurrency(articles[i].price);
                if (articles[i].isHeightVariable) {
                    priceString += " " + this.$t('per.x', {x: this.$t(this.getInfos.measurementUnit)});
                }

                let minPriceString = null;
                if (articles[i].isHeightVariable && articles[i].minPrice) {
                    minPriceString = this.getNumberWithCurrency(articles[i].minPrice)
                }

                papers.push({
                    value: articles[i].id,
                    text: articles[i].paper,
                    price: priceString,
                    minPrice: minPriceString,
                });
            }

            return papers;
        },
        getFormatsByPaper(paper) {
            let formats = [];

            let articles = this.getInfos.articles;
            for (let i = 0, j = articles.length; i < j; i++) {

                if (paper && articles[i].paper !== paper) {
                    continue;
                }

                formats.push({
                    value: articles[i].id,
                    width: articles[i].width,
                    height: articles[i].height,
                    text: this.getArticleFormat(articles[i]) + " (" + this.getNumberWithCurrency(articles[i].price) + ")"
                });
            }

            return formats.sort(this.articleFormatSortFunc);
        },
        replacePicture(i) {
            let fileRef = this.$refs['pictures-' + i];

            this.pictures[i].file = fileRef[0].files[0];
            this.pictures[i].thumbnail = null;
            this.pictures[i].thumbnailUrl = null;
            this.pictures[i].coordinates = null;
            this.pictures[i].thumbnailCroppedUrl = null;
            this.pictures[i].uploadPercentage = 0;
            this.pictures[i].error = null;
            this.pictures[i].isDone = false;
            this.pictures[i].inProgress = false;
        },
        deletePicture(key) {
            this.deleteOrderRequestItem(this.pictures[key]);
            this.pictures.splice(key, 1);
        },
        clonePictureSuccess(key, newId, copyStatus, copyMeta) {
            let picture = this.pictures[key];

            let firstFormat = this.getFirstFormat();
            let firstArticleID = this.getFirstArticleIDByFormat(firstFormat);
            let firstPaper = this.getFirstPaperByFormat(firstFormat)

            let uploadPercentage = 0;
            let error = null;
            let isDone = false;
            let inProgress = false;

            if (copyStatus) {
                uploadPercentage = picture.uploadPercentage;
                error = picture.error;
                isDone = picture.isDone;
                inProgress = picture.inProgress;
            }

            let thumbnail = null;
            let thumbnailUrl = null;
            let coordinates = null;
            let width = null;
            let height = null;
            let thumbnailCroppedUrl = null;
            let ratio = null;

            if (copyMeta) {
                thumbnail = picture.thumbnail;
                thumbnailUrl = picture.thumbnailUrl;
                coordinates = picture.coordinates;
                width = picture.width;
                height = picture.height;
                thumbnailCroppedUrl = picture.thumbnailCroppedUrl;
                ratio = picture.ratio;
            }

            let sortVal = (picture.sort + (0.1 / this.sortModifier));
            this.sortModifier *= 10;

            let newFileStruct = {
                sort: sortVal,

                id: newId,
                format: firstFormat,
                paper: firstPaper,
                amount: 1,
                articleID: firstArticleID,

                measurementValue: 0.0,

                file: picture.file,

                thumbnail: thumbnail,
                thumbnailUrl: thumbnailUrl,
                coordinates: coordinates,
                width: width,
                height: height,
                thumbnailCroppedUrl: thumbnailCroppedUrl,
                ratio: ratio,

                uploadPercentage: uploadPercentage,
                error: error,
                isDone: isDone,
                inProgress: inProgress,
            };

            /*
            const insert = (arr, index, newItem) => [
                ...arr.slice(0, index),
                newItem,
                ...arr.slice(index)
            ];

            this.pictures = insert(this.pictures, key + 1, newFileStruct);
             */

            this.pictures.push(newFileStruct);
            let newItemKey = this.pictures.length - 1;

            this.getAndSetMeasurement(newItemKey)

            let self = this;
            setTimeout(function () {
                const cropperElems = self.$refs["cropper" + newItemKey];

                if (cropperElems && cropperElems[0]) {
                    const cropperElem = cropperElems[0];

                    /*
                    cropperElem.setCoordinates({
                        width: picture.coordinates.width,
                        height: picture.coordinates.height,
                        left: picture.coordinates.left,
                        top: picture.coordinates.top,
                    });
                    */

                    cropperElem.reset();
                }

                let offset = 165;
                if (self.getInfos.terminal) {
                    offset = 165;
                }

                let elementId = "picture-hr-" + (newItemKey);
                UIkit.scroll("#" + elementId, {offset: offset}).scrollTo("#" + elementId);
            }, 10);
        },
        handleFilesUpload() {
            let files = this.$refs['pictures'].files;

            /*
            if (files.length > this.getInfos.maxPicturesPerOrder) {
                this.picturesError = this.$t('pictures.max.reached.drop', {x: this.getInfos.maxPicturesPerOrder});
                return;
            }
             */

            this.processFilesUpload(files);
        },
        getFirstFormat() {
            let formats = this.getFormats;

            let favoriteArticle = this.getFavoriteArticle();
            if (favoriteArticle) {
                return this.getArticleFormat(favoriteArticle);
            }

            return formats[0];
        },
        getFirstPaperByFormat(format) {
            let articles = this.getInfos.articles;

            articles = articles.sort(this.articleFormatSortFunc)

            if (!format) {
                return articles[0].paper;
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                let parsedFormat = this.getArticleFormat(articles[i]);
                if (format === parsedFormat) {
                    return articles[i].paper;
                }
            }
        },
        getFavoriteArticle() {
            let articles = this.getInfos.articles;

            if (articles) {
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (articles[i].isFavorite) {
                        return articles[i];
                    }
                }
            }

            return null;
        },
        getFirstArticleIDByFormat(format, paper) {
            if (!format) {
                if (!this.getInfos.articles) {
                    return 0;
                }

                let favoriteArticle = this.getFavoriteArticle();
                if (favoriteArticle) {
                    return favoriteArticle.id;
                }

                return this.getInfos.articles[0].id;
            }

            let articles = this.getInfos.articles;

            articles = articles.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));

            articles = articles.sort(this.articleFormatSortFunc)

            if (paper) {
                paper = this.getPaperName(paper);

                for (let i = 0, j = articles.length; i < j; i++) {
                    let parsedFormat = this.getArticleFormat(articles[i]);
                    if (format === parsedFormat && paper === this.getPaperName(articles[i].paper)) {
                        return articles[i].id;
                    }
                }
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                let parsedFormat = this.getArticleFormat(articles[i]);
                if (format === parsedFormat) {
                    return articles[i].id;
                }
            }
        },
        getArticleFormat(article) {
            if (article.name) {
                return article.name;
            }

            if (article.isHeightVariable) {
                return this.roundWidth(article.width) + ' ' + this.$t(this.getInfos.measurementUnit) + ' x ' + this.$t('pictureLength');
            }

            return this.roundWidth(article.width) + ' x ' + this.roundHeight(article.height) + ' ' + this.$t(this.getInfos.measurementUnit);
        },
        articleFormatSortFunc(a, b) {
            if (a.width < b.width) return -1;
            if (a.width > b.width) return 1;
            return 0;
        },
        picturestSortFunc(a, b) {
            if (a.sort < b.sort) return -1;
            if (a.sort > b.sort) return 1;
            return 0;
        },
        getFirstArticleIDByPaper(paper) {
            if (!paper) {
                return this.getInfos.articles[0].id;
            }

            let articles = this.getInfos.articles;

            articles = articles.sort(this.articleFormatSortFunc)

            for (let i = 0, j = articles.length; i < j; i++) {
                if (articles[i].paper === paper) {
                    return articles[i].id;
                }
            }
        },
        processFileEntries(files) {
            let self = this;

            let firstFormat = this.getFirstFormat();
            let firstArticleID = this.getFirstArticleIDByFormat(firstFormat);
            let firstPaper = this.getFirstPaperByFormat(firstFormat);

            let sortModifier = 0;
            if (self.pictures && self.pictures.length > 0) {
                sortModifier = self.pictures[self.pictures.length - 1].sort + 1;
            }

            for (let i = 0, j = files.length; i < j; i++) {

                if (self.pictures.length >= this.getInfos.maxPicturesPerOrder) {
                    this.pictureLimitErrors.push(files[i].name);
                    continue;
                }

                let newFileStruct = {
                    sort: sortModifier + i,

                    id: uuid.v4(),
                    format: firstFormat,
                    paper: firstPaper,
                    amount: 1,
                    articleID: firstArticleID,
                    measurementValue: 0.0,

                    file: files[i],
                    thumbnail: null,

                    thumbnailUrl: null,
                    coordinates: null,
                    thumbnailCroppedUrl: null,
                    ratio: null,

                    uploadPercentage: 0,
                    error: null,
                    isDone: false,
                    inProgress: false,
                };

                self.pictures.push(newFileStruct);
            }
        },
        setPictureError() {
            if (this.pictureTypeErrors.length > 0) {
                this.picturesError = this.$t("files.type.error", {
                    name: this.$t('pictures'),
                    types: this.getFormattedTypesString(this.fileExtensions)
                });
                return;
            }

            if (this.pictureSizeErrors.length > 0) {
                this.picturesError = this.$t("files.size.error", {
                    name: this.$t('pictures'),
                    mb: this.getMaxFileSizeInMB
                });
                return;
            }

            if (this.pictureLimitErrors.length > 0) {
                this.picturesError = this.$t('pictures.max.reached.drop', {x: this.getInfos.maxPicturesPerOrder});
            }
        },
        showPictureErrors() {
            // this.setPictureError();
            const messageTimeout = 0;

            if (this.pictureTypeErrors.length > 0) {
                UIkit.notification({
                    message: this.$t("files.type.error", {
                        name: this.$t('pictures'),
                        types: this.getFormattedTypesString(this.fileExtensions)
                    }) + ".",
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureTypeErrors = [];
            }

            if (this.pictureSizeErrors.length > 0) {
                UIkit.notification({
                    message: this.$t("files.size.error", {
                        name: this.$t('pictures'),
                        mb: this.getMaxFileSizeInMB
                    }) + ".",
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureSizeErrors = [];
            }

            if (this.pictureLimitErrors.length > 0) {
                UIkit.notification({
                    message: this.$t('pictures.max.reached.drop', {x: this.getInfos.maxPicturesPerOrder}) + ".",
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureLimitErrors = [];
            }
        },
        processFilesUpload(files) {
            this.picturesError = null;
            let parsedFiles = [];

            for (let i = 0, j = files.length; i < j; i++) {

                let fileExtension = files[i].name.split('.').pop();
                fileExtension = fileExtension.toLowerCase();
                if (!this.fileExtensions.includes(fileExtension)) {
                    this.pictureTypeErrors.push(files[i].name);
                    continue;

                    /*
                    this.picturesError = this.$t("files.type.error", {
                        name: this.$t('pictures'),
                        types: this.getFormattedTypesString(this.fileExtensions)
                    });

                    return;
                     */
                }

                if (files[i].size > (this.getMaxFileSizeInMB * 1024 * 1024)) {
                    this.pictureSizeErrors.push(files[i].name);
                    continue;

                    /*
                    this.picturesError = this.$t("files.size.error", {
                        name: this.$t('pictures'),
                        mb: this.getMaxFileSizeInMB
                    });
                    return;
                */
                }

                parsedFiles.push(files[i]);
            }

            if (parsedFiles.length === 0) {
                this.showPictureErrors();
            }

            /*
            let checkedFiles = [];
            for (let i = 0, j = parsedFiles.length; i < j; i++) {
                let self = this;

                try {
                let fileReader = new FileReader();
                fileReader.onloadend = function (e) {
                    let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                    let header = '';
                    for (let i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16);
                    }

                    let type = 'unknown';
                    switch (header) {
                        case '89504e47':
                            type = 'image/png';
                            break;
                        case "ffd8ffe0":
                        case "ffd8ffe1":
                        case "ffd8ffe2":
                        case "ffd8ffe3":
                        case "ffd8ffe8":
                        case "ffd8ffdb":
                            type = 'image/jpeg';
                            break;
                    }

                    if (type === 'unknown') {
                        // console.log(header);

                        self.pictureTypeErrors.push(parsedFiles[i].name);


                        //self.picturesError = self.$t("files.type.error", {
                        //    name: self.$t('pictures'),
                      //      types: self.getFormattedTypesString(self.fileExtensions)
                        //});
                        //return;

                    } else {
                        checkedFiles.push(parsedFiles[i]);
                    }


                   // if (self.picturesError === null && i === (files.length - 1)) {
                     //   self.processFileEntries(files);
                    //}


                    if (i === (parsedFiles.length - 1)) {
                        self.processFileEntries(checkedFiles);
                        self.showPictureErrors();
                    }

                };
                fileReader.readAsArrayBuffer(parsedFiles[i]);

                } catch (e) {
                    self.pictureTypeErrors.push(parsedFiles[i].name);

                }

            }
            // onload
            */

            this.processFileEntries(parsedFiles);
            this.showPictureErrors();

        },
        thumbnailQueue() {
            let self = this;
            this.intervalThumbnail = setInterval(() => {

                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].thumbnailing) {
                        return;
                    }
                }

                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].thumbnailUrl) {
                        continue;
                    }

                    self.pictures[i].thumbnailing = true;
                    self.imageScaleDown(i);

                    return;
                }

            }, 100);
        },
        thumbnailQueueTerminall() {
            let self = this;
            setInterval(() => {

                if (!self.isTerminalMode) {
                    return;
                }

                if (!self.terminalFiles) {
                    return;
                }

                if (self.terminalFilesReplacing) {
                    return;
                }

                if (self.terminalFiles.length === 0) {
                    return;
                }

                for (let i = 0, j = self.terminalFiles.length; i < j; i++) {
                    if (self.terminalFiles[i].thumbnailing) {
                        return;
                    }
                }

                for (let i = 0, j = self.terminalFiles.length; i < j; i++) {
                    if (self.terminalFiles[i].thumbnailUrl) {
                        continue;
                    }

                    if (self.terminalFiles[i].isDir) {
                        continue;
                    }

                    self.terminalFiles[i].thumbnailing = true;
                    self.fetchFileTerminal(i);

                    return;
                }

            }, 100);
        },
        uploadQueue() {
            let self = this;
            this.intervalUpload = setInterval(() => {

                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].inProgress) {
                        return;
                    }
                }

                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].isDone) {
                        continue;
                    }

                    if (!self.pictures[i].file) {
                        continue;
                    }

                    if (!self.pictures[i].thumbnail) {
                        continue;
                    }

                    self.pictures[i].inProgress = true;
                    self.putRequestToken(self.pictures[i]);

                    return;
                }

            }, 100);
        },

        fetchFileTerminal(key) {
            const file = this.terminalFiles[key];

            let self = this;
            axios({
                method: 'get',
                url: self.apiUrlTerminal + "/files/" + file.device + file.path + "/" + file.name,
                responseType: 'blob',

            }).then(function (response) {
                let object = new File([response.data], file.name);
                Vue.set(self.terminalFiles[key], "file", object)
                self.imageScaleDownTerminal(key);

            });
        },
        imageScaleDownTerminal(key) {
            const file = this.terminalFiles[key].file;
            let self = this;

            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                Vue.set(self.terminalFiles[key], "error", "cannot load image");
            };
            img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = self.calculateAspectRatioFit(img.width, img.height, 436, 638);

                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        Vue.set(self.terminalFiles[key], "thumbnail", blob);
                        Vue.set(self.terminalFiles[key], "thumbnailUrl", self.getLocalFileUrl(blob));
                        Vue.set(self.terminalFiles[key], "thumbnailing", false);
                    },
                    file.type,
                    0.5,
                );
            };
        },
        imageScaleDown(key) {
            const file = this.pictures[key].file;
            let self = this;

            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                Vue.set(self.pictures[key], "error", "cannot load image");
            };
            img.onload = function () {
                URL.revokeObjectURL(this.src);

                Vue.set(self.pictures[key], "width", img.width);
                Vue.set(self.pictures[key], "height", img.height);
                const [newWidth, newHeight, ratio] = self.calculateAspectRatioFit(img.width, img.height, 436, 638);
                Vue.set(self.pictures[key], "ratio", ratio);

                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        Vue.set(self.pictures[key], "thumbnail", blob);
                        Vue.set(self.pictures[key], "thumbnailUrl", self.getLocalFileUrl(blob));
                        Vue.set(self.pictures[key], "thumbnailing", false);
                    },
                    file.type,
                    0.5,
                );
            };
        },
        getMimeType(file, fallback = null) {
            const byteArray = (new Uint8Array(file)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
                header += byteArray[i].toString(16);
            }
            switch (header) {
                case "89504e47":
                    return "image/png";
                case "47494638":
                    return "image/gif";
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    return "image/jpeg";
                default:
                    return fallback;
            }
        },
        calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }

            return [width, height];
        },
        setValidContactFields(valid, fieldKey) {
            this.contactFields[fieldKey].valid = valid
        },
        setValidAddressFields(valid, fieldKey) {
            this.addressFields[fieldKey].valid = valid
        },
        setTermsValid(valid) {
            this.termsValid = valid;
        },
        validatePictures() {
            if (this.pictures.length === 0) {
                this.picturesError = this.$t('x.required.message', {x: this.$t('pictures')});
                return;
            }

            if (!this.isPicturesValid) {
                let elementId = "too-length";
                UIkit.scroll("#" + elementId, {offset: 200}).scrollTo("#" + elementId);
                return;
            }

            UIkit.tab('#tab').show(1);
        },
        validateContactDetails() {
            this.triggerContactFields++;

            if (!this.isContactDetailsValid) {
                return;
            }

            if (this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;

                for (let i = 0, j = this.addressFields.length; i < j; i++) {
                    if (!this.addressFields[i].valid) {
                        return;
                    }
                }
            }

            if (this.paymentMethod === "online") {
                this.postOrderRequestPayment();
            }

            UIkit.tab('#tab').show(2);
        },
        complete() {
            this.triggerTerms++;

            let street = null;
            let zip = null;
            let city = null;
            let country = null;
            if (this.isAddressRequired || this.getInfos.mandatoryFields.includes('address')) {
                this.triggerAddressFields++;

                for (let i = 0, j = this.addressFields.length; i < j; i++) {
                    if (!this.addressFields[i].valid) {
                        return;
                    }
                }

                street = this.addressFields[0].value;
                zip = this.addressFields[1].value;
                city = this.addressFields[2].value;
                country = this.country;
            }

            if (!this.termsValid) {
                return
            }

            this.loadingOrder = true;

            let parsedPictures = [];
            for (let i = 0, j = this.pictures.length; i < j; i++) {

                if (this.pictures[i].error !== null) {
                    continue;
                }

                const thumbnailCoordinates = this.pictures[i].coordinates;
                const pictureCoordinates = this.getOriginDimensions(thumbnailCoordinates, this.pictures[i].ratio);

                let width = pictureCoordinates.width;
                let height = pictureCoordinates.height;


                if (width > this.pictures[i].width) {
                    width = this.pictures[i].width;
                }
                if (height > this.pictures[i].height) {
                    height = this.pictures[i].height;
                }


                let top = parseInt(pictureCoordinates.top);
                if (top < 0) {
                    top = 0
                }

                let left = parseInt(pictureCoordinates.left);
                if (left < 0) {
                    left = 0
                }

                let thumbnailTop = parseInt(thumbnailCoordinates.top)
                if (thumbnailTop < 0) {
                    thumbnailTop = 0
                }

                let thumbnailLeft = parseInt(thumbnailCoordinates.left)
                if (thumbnailLeft < 0) {
                    thumbnailLeft = 0
                }

                parsedPictures.push({
                    id: this.pictures[i].id,
                    amount: this.pictures[i].amount,
                    articleId: this.pictures[i].articleID,
                    sort: this.pictures[i].sort,
                    measurementValue: this.pictures[i].measurementValue,

                    pictureCroppedWidth: parseInt(width),
                    pictureCroppedHeight: parseInt(height),
                    pictureCroppedTop: top,
                    pictureCroppedLeft: left,

                    thumbnailCroppedHeight: parseInt(thumbnailCoordinates.height),
                    thumbnailCroppedWidth: parseInt(thumbnailCoordinates.width),
                    thumbnailCroppedTop: thumbnailTop,
                    thumbnailCroppedLeft: thumbnailLeft,
                });
            }

            let platform = "browser";
            if (this.isApp) {
                platform = "app";
            }

            if (this.isTerminalMode) {
                platform = "terminal";
            }

            let http = axios.create();
            http.post(this.apiUrl + '/orders', {
                requestId: this.requestToken,
                name: this.contactFields[0].value,
                email: this.contactFields[1].value,
                telephone: this.contactFields[2].value,
                paymentMethod: this.paymentMethod,
                deliveryType: this.deliveryType,
                comments: this.comments,
                pictures: parsedPictures,
                language: this.$i18n.locale,
                platform: platform,

                street,
                zip,
                city,
                country,

            }).then(() => {
                this.errorOrder = null;
                this.loadingOrder = false;
                this.successOrder = true;

                if (this.isTerminalWithAds) {
                    let self = this;
                    setTimeout(function () {
                        UIkit.modal("#" + self.modalIdTerminalAdsScreensaver).show();
                    }, 10);
                    return;
                }

                if (!this.isTerminalMode) {
                    UIkit.modal("#" + this.modalIdOrderSuccess).show();
                }

            }).catch(e => {
                this.checkGenericError(e);
                this.errorOrder = e;
                this.loadingOrder = false;
                this.successOrder = false;

            });
        },
    }
}

</script>

<style lang="scss" scoped>

.action-name {
    font-size: $site-font-size-large;
}

.drop-struct {
    background-color: rgba(34, 34, 34, 0.3);
    backdrop-filter: saturate(180%) blur(20px);
    max-width: 400px;
    height: 400px;
    margin: auto;
}

.section-title {
    font-size: 18px;
    font-weight: 500;
    color: $site-background;
    margin-bottom: 10px;
}

.image-struct {
    // border: 1px solid #e5e5e5;
}

.image-small {
    max-width: 40px;
}

.badge {
    font-size: 16px;
    padding: 10px 15px;
    margin-top: -5px;
    margin-right: 5px;
}

.custom-label {
    padding: 5px 10px !important;
    border-radius: 15px !important;
}

.custom-input {
    width: 60px;
    margin: auto;
}

.large-badge {
    padding: 20px 40px;
    background: $info-color;
    // margin-right: 15px !important;
}

.uk-overlay {
    padding: 10px !important;
}

.upload-status {
    margin-top: -15px;
    font-size: 13px;
}

.upload-status-total {
}

.total-struct {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff !important;
}

.upload-status-struct {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background: #fff !important;
}

.uk-card-secondary {
    // border-top: 1px solid #e5e5e5;
}

.uk-accordion-title {
    font-size: 16px !important;
}

.sticky-accordion-content {
    margin-right: 32.391px;
}

.image-error {
    background-color: #ef274b !important;
}

.item-amount {
    font-size: 16px;
}

.paper-text {
    font-weight: 500;
    word-break: break-all;
}

.paper-price {
    font-size: 14px;
}

.uk-input {
    font-weight: 500;
    color: $site-background-hover !important;
}

.uk-select {
    font-weight: 500;
    color: $site-background-hover !important;
}

.graduated-prices {
    font-size: 14px;
}


.thumbnail {
    height: 425px;
    color: #fff;
}

.thumbnail-small {
    border: 2px solid #e5e5e5;
}

@media (min-width: $breakpoint-medium) {
    .thumbnail {
        height: 179px;
    }
}

.dragover {
    border-color: $info-color;
}

.vat {
    font-size: 14px;
    margin-top: 15px;
}

.icon-distance {
    margin-right: 3px;
}

.cropper {
    background: #000;
}

.cropping-message {
    font-size: 14px;
    margin-top: 5px;
}

.upload-border {
    border-left: 2px solid #e5e5e5;
    border-right: 2px solid #e5e5e5;
    border-bottom: 2px solid #e5e5e5;
    padding: 15px 8px 15px 8px;
}

.uk-progress {
    height: 13px !important;
}

.cropper-less {

    width: 100%;
    height: 100%;
}

.cropper-disabled {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    opacity: 0.0;
    border: 15px solid transparent;
    width: 100%;
    height: 100%;
}

.control {
    margin-top: 0px;
}

.cropper-item-disabled {
    opacity: 0.9;
}

.crop-icon-hovered {
    color: $warning-color;
}

.resolution-message {
    font-size: $site-font-size-xsmall;
}

.uk-switcher > :not(.uk-active) {
    display: block !important;
    position: fixed !important;
    top: -999999px !important;
    left: -999999px !important;
}

.total-costs-text {
    font-weight: 500;
}

.terminal-content {
    min-height: calc(100vh - 40px - 50px - 48px - 60px - 53px);
}

.terminal-content-loading {
    min-height: calc(100vh - 105px - 50px - 48px - 40px - -17px);
}

.device-in-info {
    color: $site-background;
    font-size: $site-font-size-large;
}

.folder-struct {
    color: #000000 !important;
}

.folder-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dir-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    margin: 0 10px 0 calc(10px - 4px) !important;
}

.image-item-struct {
    max-height: 150px;
    margin: auto;
}

.image-item {
    height: 100%;
    margin: auto;
}

.action-button-struct {
    padding-top: 20px;
    box-shadow: 0 -14px 25px rgba(0, 0, 0, 0.16);
}

</style>
