<template>
    <div>
        <div class="uk-padding logo-struct">
            <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-expand" />
                <div class="logo-box uk-width-auto">
                    {{ getInfos.transfer.name }}
                    <span class="uk-margin-small-right" />
                    <img
                        :src="apiUrl + '/logos/' + getInfos.logo"
                        class="logo" />
                </div>
            </div>
        </div>

        <LoadingStruct
            class="transfer-struct"
            is-dark
            :is-loading="loading"
            :error="error">
            <countdown
                v-if="
                    transfer && transfer.transfer && transfer.transfer.expiresAt
                "
                class="uk-hidden"
                :end-time="new Date(transfer.transfer.expiresAt).getTime()"
                @finish="reload" />

            <Card
                is-round
                v-if="error && error.message.includes('404')"
                :title="$t('transfer.does.not.exist')"
                is-dark
                class="uk-text-left"
                mode="default"
                is-centered>
                <InfoBox class="uk-text-left" mode="danger">
                    <Translate
                        translation-key="transfer.does.not.exist.message" />
                </InfoBox>
            </Card>

            <Card
                v-else
                is-round
                is-dark
                is-without-body
                class="card-struct"
                mode="default"
                is-centered>
                <div class="transfer-content-struct">
                    <div class="uk-text-center transfer-name">
                        {{ transfer.transfer.name }}
                    </div>

                    <div
                        class="uk-text-left word-struct"
                        v-if="transfer.transfer.message">
                        <InfoBox
                            class="transfer-message"
                            borderless
                            slim
                            is-round
                            mode="blank">
                            {{ transfer.transfer.message }}
                        </InfoBox>
                    </div>

                    <div class="uk-margin-small-top" />
                    <a
                        :uk-tooltip="
                            'title:' +
                            $t(
                                disabledDownload
                                    ? 'transfer.available.shortly'
                                    : 'download'
                            )
                        "
                        :href="
                            disabledDownload
                                ? '#'
                                : apiUrl +
                                  '/transfers/' +
                                  transferToken +
                                  '/' +
                                  transfer.transfer.name +
                                  '?session=' +
                                  transfer.token
                        ">
                        <Button
                            :is-disabled="disabledDownload"
                            is-round
                            is-large
                            is-min-width
                            mode="success-alt"
                            :is-loading="loadingDownload">
                            <countdown
                                v-if="disabledDownload"
                                :end-time="
                                    new Date(transfer.accessibleAt).getTime()
                                "
                                @finish="unlockDownload">
                                <span slot="process" slot-scope="{timeObj}">
                                    {{ timeObj.ceil.s }}
                                </span>
                            </countdown>

                            <Icon v-else icon="cloud-download" :ratio="1.4" />
                        </Button>
                    </a>

                    <div class="uk-margin-small-top transfer-size">
                        {{
                            $t('transfer.size') +
                            ': ' +
                            getParsedBytes(transfer.transfer.sizeInBytes)
                        }}
                    </div>

                    <div class="uk-margin-small-top word-struct">
                        <InfoBox
                            borderless
                            mode="blank"
                            slim
                            is-round
                            class="delete-message">
                            <span
                                :uk-tooltip="
                                    'title:' +
                                    $t('On') +
                                    ' ' +
                                    getFormattedDateTimeByDateString(
                                        transfer.transfer.expiresAt
                                    ) +
                                    ' ' +
                                    $t('clock')
                                ">
                                <span class="expires-at-text">
                                    <Translate translation-key="expires.at" />
                                    {{
                                        getDaysLeftByDateString(
                                            transfer.transfer.expiresAt,
                                            false
                                        )
                                    }}
                                </span>
                            </span>
                        </InfoBox>
                    </div>
                </div>
                <div class="transfer-report">
                    <a
                        :uk-tooltip="'title:' + $t('transfer.report.message')"
                        class="uk-link-muted"
                        target="_blank"
                        :href="getReportURL()">
                        <Translate translation-key="transfer.report" />
                    </a>
                </div>
            </Card>
        </LoadingStruct>
    </div>
</template>

<script>
import LoadingStruct from '@/views/LoadingStruct';
import Card from '@/components/generic/Card';
import axios from 'axios';
import Translate from '../components/Translate.vue';
import Button from '../components/generic/Button.vue';
import InfoBox from '../components/generic/InfoBox.vue';
import Icon from '@/components/generic/Icon.vue';

export default {
    name: 'Transfer',
    components: {
        Icon,
        InfoBox,
        Button,
        Translate,
        Card,
        LoadingStruct,
    },
    props: {
        token: String,
    },
    data() {
        return {
            loading: true,
            error: null,

            transfer: {
                accessibleAt: '',
                token: '',
                transfer: {
                    expiresAt: '',
                    name: '',
                    sizeInBytes: 0,
                },
            },

            loadingDownload: false,
            disabledDownload: true,

            transferToken: '',

            heartbeatInterval: 0,
        };
    },
    created() {
        if (this.token) {
            this.transferToken = this.token;
        } else {
            this.transferToken = this.$route.params.transfer;
        }

        this.getTransfer();
    },
    beforeDestroy() {
        clearInterval(this.heartbeatInterval);
    },
    methods: {
        unlockDownload() {
            this.disabledDownload = false;
        },
        getReportURL() {
            return (
                'https://phy-prints.com/report?report=' + window.location.href
            );
        },
        heartbeatTransfer() {
            const transferToken = this.transferToken;

            let http = axios.create();
            http.patch(
                this.apiUrl +
                    '/transfers/' +
                    transferToken +
                    '/sessions/' +
                    this.transfer.token
            )
                .then(() => {})
                .catch(() => {});
        },
        getTransfer() {
            let self = this;
            const transferToken = this.transferToken;

            let http = axios.create();
            http.get(this.apiUrl + '/transfers/' + transferToken)
                .then((response) => {
                    this.transfer = response.data;

                    this.error = null;
                    this.loading = false;

                    setInterval(function () {
                        self.heartbeatTransfer();
                    }, 1000);
                })
                .catch((e) => {
                    this.checkGenericError(e);
                    this.error = e;
                    this.loading = false;
                });
        },
        actionDownload() {
            event.preventDefault();
            const transferToken = this.$route.params.transfer;

            let self = this;
            self.loadingDownload = true;

            let request = new XMLHttpRequest();
            request.open(
                'GET',
                this.apiUrl +
                    '/transfers/' +
                    transferToken +
                    '/' +
                    self.transfer.transfer.name,
                true
            );
            request.responseType = 'blob';
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = self.transfer.transfer.name;
                link.click();

                self.loadingDownload = false;
            };

            request.send();
        },
    },
};
</script>

<style lang="scss" scoped>
.word-struct {
    word-wrap: break-word;
}

.transfer-message {
    color: $site-background;
    font-size: 14px;
}

.transfer-struct {
    max-width: 400px;
    min-height: calc(100vh - 217px);
    padding-left: 20px;
}

.expires-at-text {
    color: #999;
}

.transfer-report {
    font-size: 12px;
    padding: 15px 0;
    //background-color: #F9F9F9;
    //border-color: #F9F9F9;
}

.delete-message {
    font-size: 14px;
}

.transfer-report-message {
    color: #999;
    font-size: 13px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.logo-box {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(29, 29, 31, 0.3) !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 500;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.card-struct {
    border-left-width: 3px;
    border-top-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: #91d800;
    border-radius: 5px;

    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.transfer-content-struct {
    border-radius: 5px;
    padding-top: 20px;
    padding-right: 2px;
    padding-left: 2px;
    background-color: #ffffff;
    padding-bottom: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.01);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.transfer-name {
    font-size: 20px;
    margin-bottom: 15px;
    color: $site-background;
}

.transfer-size {
    font-size: 14px;
    font-weight: 500;
    color: $site-background;
}
</style>
