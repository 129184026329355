<template>
    <span>
        {{ $t(translationKey, parameter)+suffix }}
    </span>
</template>


<script>

export default {
    name: 'Translate',
    props: {
        translationKey: String,
        parameter:Object,
        suffix: {
            type: String,
            default: '',
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
